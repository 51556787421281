// eslint-disable-next-line no-unused-vars
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LanguageIcon from "@material-ui/icons/Language";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneEnabledOutlinedIcon from "@material-ui/icons/PhoneEnabledOutlined";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import Attachment from "Components/Attachment/Attachment";
import ElevatorCommands from "Components/ElevatorCommands/ElevatorCommands";
import ElevatorMaintenance, {
  IMaintenanceDataForHistoryDetails,
} from "Components/ElevatorMaintenance/ElevatorMaintenance";
import ElevatorStatisticsGraph from "Components/ElevatorStatisticsGraphs/ElevatorStatisticsGraphs";
import ElevatorSummary from "Components/ElevatorSummary/ElevatorSummary";
import EnergyUsageChart from "Components/EnergyUsageChart/EnergyUsageChart";
import Lift from "Components/Lift/Lift";
import OpenIssues from "Components/OpenIssues/OpenIssues";
import Periods from "Components/Periods/Periods";
import SmallCardsGrid from "Components/SmallCardsGrid/SmallCardsGrid";
import StickyHeader from "Components/StickyHeader/StickyHeader";
import Visits from "Components/Visits/Visits";
import useCallReportService from "Hooks/useCallReportService.hook";
import { filter, first, isEmpty } from "lodash";
import moment from "moment";
import AttachmentsProvider, {
  AttachmentsContext,
} from "Providers/AttachmentsProvider";
import LiftCounterDataProvider, {
  LiftCounterDataContext,
} from "Providers/ElevatorMaintenanceServices/LiftCounterDataProvider";
import LiftRunCounterProvider from "Providers/ElevatorMaintenanceServices/LiftRunCounterProvider";
import LiftVistsProvider, {
  LiftVisitsContext,
} from "Providers/ElevatorMaintenanceServices/LiftVisitsProvider";
import EventSearchDataProvider, {
  EventSearchDataContext,
  IEventDataRequest,
} from "Providers/EventDataProvider";
import {
  EGlobalStateCases,
  GlobalStateContext,
  IGlobalState,
} from "Providers/GlobalStateProvider";
import LiftDetailsProvider, {
  LiftDetailsContext,
} from "Providers/LiftDetailsProvider";
import LiftStatsProvider, {
  LiftStatsContext,
} from "Providers/LiftStatsProvider";
import PeriodicalCounterSumStatsProvider, {
  PeriodicalCounterSumContext,
} from "Providers/PeriodicalCounterProvider";
import SenseModuleProvider from "Providers/SenseModuleProvider";
import SenseModuleSeriesProvider from "Providers/SenseModuleSeriesProvider";
import SenseModuleSummaryProvider from "Providers/SenseModuleSummaryProvider";
import React, { useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation, useParams } from "react-router-dom";
import VisibilitySensor from "react-visibility-sensor";
import useWindowSize from "Utils/Hooks/useWindowSize";
import { LiftStatusStorage } from "Utils/Storage";
import classes from "./LiftDetails.module.scss";
import LiftDetailsDetails from "./LiftDetailsComponents/LiftDetailsDetails/LiftDetailsDetails";
import LiftDetailsEmergencyTelephoneSystem from "./LiftDetailsComponents/LiftDetailsEmergencyTelephoneSystem/LiftDetailsEmergencyTelephoneSystem";
import LiftDetailsHistory from "./LiftDetailsComponents/LiftDetailsHistory/LiftDetailsHistory";
import LiftDetailsInstallation from "./LiftDetailsComponents/LiftDetailsInstallition/LiftDetailsInstallation";
import LiftDetailsOwnerOrganisations from "./LiftDetailsComponents/LIftDetailsOwnerOrganizations/LiftDetailsOwnerOrganisations";
import LiftDetailsRealTime from "./LiftDetailsComponents/LiftDetailsRealTime/LiftDetailsRealTime";
import LiftSBCLInstallation from "./LiftDetailsComponents/LiftDetailsSBCL/LiftSBCLInstallation";
import LiftDetailsSettings from "./LiftDetailsComponents/LiftDetailsSettings/LiftDetailsSettings";

enum ACCORDION {
  REALTIME = 1,
  HISTORY = 2,
  OVERVIEW = 3,
  VISIT_MAINTENANCE = 4,
  ACTIONS = 5,
  STATISTICS = 6,
  ORGANISATIONS = 7,
  EMERGENCY_TELEPHONE_SYSTEM = 8,
  ADJUSTMENTS = 9,
  MAINDATA = 10,
  SETTINGS = 11,
  ATTATCHEMENTS = 12,
  DETAILS = 13,
  VISIT_APPROVING = 14,
}

enum LIFTEVENT {
  VISIT = "P-LIFT-SENSE-VISIT",
  UNAVAILABLE = "P-LIFT-UNAVAILABLE",
  UNHEALTHY = "P-LIFT-SENSE-DEVICE_COMMUNICATION_UNHEALTHY",
}

const LiftDetailsInternal = (props) => {
  // Components internal state
  //
  const location = useLocation();
  const [eventDataFromLocation, setEventDateFromLocation] = useState<any>();
  const { liftId } = useParams();
  const [liftData, setLiftData] = useState<LiftStatus.Schemas.ILift | null>(
    null
  );
  const [senseLink, setSenseLink] = useState(null);
  const [liftFlowAvailable, setLiftFlowAvailable] = useState(null);
  const [liftVisitsData, setLiftVisitsData] = useState(null);
  const [liftCounterData, setLiftCounterData] = useState([]);
  const [liftStats, setLiftStats] = useState(
    {} as LiftStatus.Schemas.IStatsOverviewDto
  );
  const [periodicalCounterData, setPerodicalCounterData] = useState(null);
  const [printing, setPrinting] = useState(false);
  const [attachments, setAttachments] = useState(null);
  const [triggerChart, setTriggerChart] = useState(true);
  const [globalState, globalStateDispatch] =
    useContext<[IGlobalState, React.Dispatch<any>]>(GlobalStateContext);
  const [liftEvents, setLiftEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorLoading, setErrorLoading] = useState(false);
  const [showHeader, setShowHeader] = useState(false);
  const [historicalPeriod, setHistoricalPeriod] = useState(false);
  const dimensions = useWindowSize() ?? [];
  const historyAccordionRef = useRef(null);
  const [dataFromMaintenanceChart, setDataFromMaintenanceChart] =
    useState<IMaintenanceDataForHistoryDetails | null>(null);

  // Providers
  const liftDetailsProvider = useContext(LiftDetailsContext);
  const liftVisitsProvider = useContext(LiftVisitsContext);
  const eventSearchDataProvider = useContext(EventSearchDataContext);
  const liftCounterProvider = useContext(LiftCounterDataContext);
  const liftStatsProvider = useContext(LiftStatsContext);
  const attachmentsProvider = useContext(AttachmentsContext);
  const periodicalCounterSumProvider = useContext(PeriodicalCounterSumContext);

  // Accordion ctrl's
  const [detailsAccordionCtrl, setDetailsAccordionCtrl] =
    useState<boolean>(false);
  const [historyAccordionCtrl, setHistoryAccordionCtrl] =
    useState<boolean>(false);
  const [realtimeAccordionCtrl, setRealtimeAccordionCtrl] =
    useState<boolean>(false);
  const [overviewAccordionCtrl, setOverviewAccordionCtrl] =
    useState<boolean>(false);
  const [visitMaintenanceAccordionCtrl, setVisitMaintenanceAccordionCtrl] =
    useState<boolean>(false);
  const [visitApprovingAccordionCtrl, setVisitApprovingAccordionCtrl] =
    useState<boolean>(false);
  const [actionsAccordionCtrl, setActionsAccordionCtrl] =
    useState<boolean>(false);
  const [statisticsAccordionCtrl, setStatisticsAccordionCtrl] =
    useState<boolean>(false);
  const [organisationsAccordionCtrl, setOrganisationsAccordionCtrl] =
    useState<boolean>(false);
  const [
    emergencyPhoneSystemAccordionCtrl,
    setEmergencyPhoneSystemAccordionCtrl,
  ] = useState<boolean>(false);
  const [mainDataAccordionCtrl, setMainDataAccordionCtrl] =
    useState<boolean>(false);
  const [settingsAccordionCtrl, setSettingsAccordionCtrl] =
    useState<boolean>(false);
  const [attachmentsAccordionCtrl, setAttachmentsAccordionCtrl] =
    useState<boolean>(false);

  // The available width for inner graph is the width
  // minus the padding (16px) and left side navigation (220px)
  //
  let availableWidth = dimensions[0] > 0 ? dimensions[0] - (220 + 16) : null;

  // We maximize the width to 1220px
  //
  if (availableWidth > 1220) {
    availableWidth = 1220;
  }

  // Remove padding (inner and out) by default
  //
  availableWidth = availableWidth - 4 * 24;

  const [reportLoading, fetchReport] = useCallReportService({
    subjectId: liftId,
    report: "lift",
  });

  // If printing set all to use to keep them open
  useEffect(() => {
    if (printing === true) {
      setDetailsAccordionCtrl(true);
      setHistoryAccordionCtrl(true);
      setRealtimeAccordionCtrl(true);
      setOverviewAccordionCtrl(true);
      setVisitMaintenanceAccordionCtrl(true);
      setVisitApprovingAccordionCtrl(true);
      setActionsAccordionCtrl(true);
      setStatisticsAccordionCtrl(true);
      setOrganisationsAccordionCtrl(true);
      setEmergencyPhoneSystemAccordionCtrl(true);
      setMainDataAccordionCtrl(true);
      setSettingsAccordionCtrl(true);
      setAttachmentsAccordionCtrl(true);
    }
  }, [printing]);

  // Listen to print events
  //
  useEffect(() => {
    function activatePrinting() {
      setPrinting(true);
    }
    function deactivatePrinting() {
      setPrinting(false);
    }

    window.addEventListener("beforeprint", activatePrinting);
    window.addEventListener("afterprint", deactivatePrinting);

    return () => {
      window.removeEventListener("beforeprint", activatePrinting);
      window.removeEventListener("afterprint", deactivatePrinting);
    };
  }, []);

  // Data loading handler and effect
  //
  useEffect(() => {
    setLoading(true);
    if (liftId) {
      const promises: Promise<any>[] = [];

      // The lift counter provider react-query state is only used for daily
      promises.push(liftCounterProvider
        .dispatchLiftCounterDataRequest({
          liftId,
          from: LiftStatusStorage.startDate,
          to: LiftStatusStorage.endDate,
          type: "daily",
        })
        .then((response) => {
          setLiftCounterData(response);
        }));

      promises.push(liftStatsProvider
        .fetch({
          liftId,
          from: LiftStatusStorage.startDate,
          to: LiftStatusStorage.endDate,
          startHour: 7,
          endHour: 19,
        })
        .then((response: LiftStatus.Schemas.IStatsOverviewDto) => {
          setLiftStats(response);
        })
        .catch((error) => console.error(error)));

      promises.push(liftVisitsProvider.fetch({
        liftId,
        from: LiftStatusStorage.startDate,
        to: LiftStatusStorage.endDate,
      }));

      promises.push(periodicalCounterSumProvider
        .fetch({
          liftId,
          sensorId: 109, // Currently hard coded to 109 but we can handle different ones
          from: LiftStatusStorage.startDate,
          to: LiftStatusStorage.endDate,
        })
        .then((result) => {
          if (result) {
            setPerodicalCounterData(result);
          }
        })
        .catch((err) => {
          console.error(err);
          setPerodicalCounterData(null);
        }));

      const eventDataRequest: IEventDataRequest = {
        eventKey: [
          "P-LIFT-SENSE-VISIT",
          "P-LIFT-UNAVAILABLE",
          "P-LIFT-UNAVAILABLE_OUTSIDE_SLA",
          "P-LIFT-SENSE-DEVICE_COMMUNICATION_UNHEALTHY",
        ],
        from: LiftStatusStorage.startDate,
        to: LiftStatusStorage.endDate,
      };

      eventDataRequest.liftId =
        typeof liftId === "string" ? parseInt(liftId, 10) : liftId;

      // Fetch all the things!
      //
      eventDataRequest.pagination = {
        limit: 999999,
        offset: 0,
      };

      promises.push(eventSearchDataProvider.fetchEventSearchData(eventDataRequest));

      Promise.allSettled(promises).then(() => {
        setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liftId, LiftStatusStorage.startDate, LiftStatusStorage.endDate, globalState.viewIsCurrent]);

  // This useEffect handles refetching the current-state for the lift every 60 seconds
  useEffect(() => {
    let refetchCurrentStatsTimer;
    clearTimeout(refetchCurrentStatsTimer);
    if (globalState.viewIsCurrent) {
      refetchCurrentStatsTimer = setTimeout(() => {
        liftStatsProvider
          .fetch({
            liftId,
            from: LiftStatusStorage.startDate,
            to: LiftStatusStorage.endDate,
            startHour: 7,
            endHour: 19,
          })
          .then((response: LiftStatus.Schemas.IStatsOverviewDto) => {
            setLiftStats(response);
          })
          .catch((error) => console.error(error))
          .then(() => {
            setLoading(false);
          });
      }, 60000);
    }

    return () => {
      clearTimeout(refetchCurrentStatsTimer);
    };
  }, [liftStats, globalState.viewIsCurrent, liftId, liftStatsProvider]);

  useEffect(() => {
    setTriggerChart(false);

    if (
      liftDetailsProvider?.response === "loading" ||
      liftVisitsProvider?.response === "loading" ||
      attachmentsProvider?.response === "loading" ||
      eventSearchDataProvider?.response === "loading"
    ) {
      return;
    } else if (!liftDetailsProvider?.response) {
      // if response fails
      // set error loading to true (we need better error handling)
      // React query never returns errors only undefiened / loading or the data.
      setErrorLoading(true);
      return;
    }
    const liftDetails =
      liftDetailsProvider?.response as LiftStatus.Schemas.ILift;
    setLiftData(liftDetails);

    // Keep a list of linked sense's for this lift
    //
    const senseLinks = filter(liftDetails?.sense_link, (link) => link.linked);
    setSenseLink(senseLinks);

    const liftEvents = eventSearchDataProvider?.response;

    setLiftEvents(liftEvents?.items);

    const liftVisits = liftVisitsProvider?.response;
    setLiftVisitsData(liftVisits);

    setAttachments(attachmentsProvider?.response);

    let isLiftFlowAvailable = false;

    if (senseLinks?.length) {
      const mySenseLink = first(senseLinks);

      if (
        (globalState.userOrganisationType === "admin" &&
          mySenseLink.connected_controller) ||
        (mySenseLink.connected_controller &&
          mySenseLink.connected_controller_released)
      ) {
        isLiftFlowAvailable = true;
      }
    }

    setLiftFlowAvailable(isLiftFlowAvailable);

    setTriggerChart(true);
    globalStateDispatch({
      type: EGlobalStateCases.setLockedLiftId,
      payload: liftId.toString(),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    liftId,
    liftDetailsProvider.response,
    liftVisitsProvider.response,
    liftStatsProvider.response,
    eventSearchDataProvider.response,
  ]);

  const accordionStateControl = (accordionId: ACCORDION, value?: any) => {
    const arrayOfAccordions = [];

    switch (accordionId) {
      case ACCORDION.MAINDATA:
        setMainDataAccordionCtrl(value);
        break;
      case ACCORDION.ORGANISATIONS:
        setOrganisationsAccordionCtrl(value);
        break;
      case ACCORDION.VISIT_MAINTENANCE:
        setVisitMaintenanceAccordionCtrl(value);
        break;
      case ACCORDION.VISIT_APPROVING:
        setVisitApprovingAccordionCtrl(value);
        break;
      case ACCORDION.EMERGENCY_TELEPHONE_SYSTEM:
        setEmergencyPhoneSystemAccordionCtrl(value);
        break;
      case ACCORDION.OVERVIEW:
        setOverviewAccordionCtrl(value);
        break;
      case ACCORDION.STATISTICS:
        setStatisticsAccordionCtrl(value);
        break;
      case ACCORDION.ACTIONS:
        setActionsAccordionCtrl(value);
        break;
      case ACCORDION.ATTATCHEMENTS:
        setAttachmentsAccordionCtrl(value);
        break;
      case ACCORDION.REALTIME:
        setRealtimeAccordionCtrl(value);
        break;
      case ACCORDION.HISTORY:
        setHistoryAccordionCtrl(value);
        break;
      case ACCORDION.DETAILS:
        setDetailsAccordionCtrl(value);
        break;
      case ACCORDION.SETTINGS:
        setSettingsAccordionCtrl(value);
        break;
    }

    const currentLocalStorageAccordionState = null;

    const addedValue = {
      accordionId,
      open: value,
    };

    if (currentLocalStorageAccordionState) {
      const filteredArray = currentLocalStorageAccordionState.filter(
        (el) => el.accordionId !== accordionId
      );

      arrayOfAccordions.push(...filteredArray, addedValue);
    } else {
      arrayOfAccordions.push(addedValue);
    }

    // Commented out just in case we want this back in the future rather then deleting this function.
    //
    // localStorage.setItem("accordionsStates", JSON.stringify(arrayOfAccordions));
    //  expandAccordionOrNot(accordionId);
  };

  // Reads local storage and returns the status of the accordion
  //https://trello.com/c/2wQHPZdW disabled
  //
  const expandAccordionOrNot = (accordionId: ACCORDION) => {
    /* const currentLocalStorageAccordionState = JSON.parse(
      localStorage.getItem("accordionsStates")
    ); */
    const currentLocalStorageAccordionState = null;

    if (currentLocalStorageAccordionState) {
      const currentAccordion = currentLocalStorageAccordionState.find(
        (el) => el.accordionId === accordionId
      );

      if (currentAccordion) {
        return currentAccordion.open;
      }
    }

    return false;
  };

  useEffect(() => {
    if (location?.state?.eventCreatedAt) {
      setEventDateFromLocation(location.state.eventCreatedAt);
    }
  }, [location.state]);

  const handleHistoryDetailsNavigationWithMaintenanceData = (
    data: IMaintenanceDataForHistoryDetails
  ) => {
    historyAccordionRef.current.scrollIntoView();
    setHistoryAccordionCtrl(true);
    // This will add the created date to let the
    // history panel know that we want move the
    // start time of the event
    //
    setEventDateFromLocation(data.navData.created_at);
    setDataFromMaintenanceChart(data);
  };

  // Don't confuse 'historical=false' with 'current=true'! The latter is realtime
  //
  useEffect(() => {
    const startDate = moment(LiftStatusStorage.startDate);
    const endDate = moment(LiftStatusStorage.endDate);
    const now = moment();

    // Check if now is in between start and end
    //
    if (
      now <= endDate &&
      now >= startDate &&
      !location?.state?.eventCreatedAt
    ) {
      setHistoricalPeriod(false);
    } else {
      setHistoricalPeriod(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [LiftStatusStorage.startDate, LiftStatusStorage.endDate, location]);

  return (
    <React.Fragment>
      <StickyHeader
        showPeriods={ ( !globalState.userClientLight && !globalState.userMechanic ) }
        liftData={liftData}
        showHeader={showHeader}
        showDeleteIcon={false}
      ></StickyHeader>

      <table className={classes.page}>
        <thead className={classes.printHeader}>
          <tr>
            <th>
              <div>
                <img
                  className={classes.ednlLogo}
                  src="/assets/images/logo.svg"
                  alt="ednl logo"
                ></img>
              </div>
            </th>
            <th>
              <div>
                <MailOutlineIcon />{" "}
                <a href="mailto:info@ednl.nl">info@ednl.nl</a>
              </div>
              <div>
                <PhoneEnabledOutlinedIcon />{" "}
                <a href="tel:0031880405300">+31 (0)88 040 5300</a>
              </div>
              <div>
                <LanguageIcon />{" "}
                <a href="https://www.ednl.nl" target="_blacnk">
                  https://www.ednl.nl
                </a>
              </div>
            </th>
          </tr>
          <tr>
            <th colSpan={2}>
              <div className={classes.printSelectedLift}>
                <div>
                  <span className={classes.printLabel}>LIFT:</span>
                  <br />
                  <span>
                    {liftData ? (
                      <Lift
                        key={liftData?.id}
                        lift={liftData}
                        disableHover={true}
                        disableIcon={true}
                        padding={0}
                      />
                    ) : (
                      ""
                    )}
                  </span>
                </div>
                <div>
                  <span className={classes.printLabel}>PERIODE:</span>
                  <br />
                  <span>
                    {LiftStatusStorage.startDate} t/m{" "}
                    {LiftStatusStorage.endDate}
                  </span>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={2}>
              <div className={classes.content}>
                {/** Periods bar */}
                <div className={classes.periods}>
                  <Periods
                    hideNavigation={true}
                    onDateChange={(dateRange) => {
                      globalStateDispatch({
                        type: EGlobalStateCases.setDatesLocal,
                        payload: {
                          startDate: dateRange.from,
                          endDate: dateRange.to,
                        },
                      });
                    }}
                  />
                </div>

                {errorLoading ? (
                  <React.Fragment>
                    <h2 className={classes.notFound}>
                      Lift details not found for ID: {liftId}
                    </h2>
                  </React.Fragment>
                ) : !isEmpty(liftData) ? (
                  <React.Fragment>
                    <SenseModuleProvider
                      esNumber={liftData?.sense_link?.[0]?.es_number}
                    >
                      <div className={classes.titleBar}>
                        <h2 className={classes.title}>
                          <FormattedMessage id="lift.details.title" />
                        </h2>
                        {!globalState.viewIsCurrent && !loading ? (
                          <>
                            {reportLoading ? (
                              <CircularProgress />
                            ) : (
                              <IconButton
                                disabled={reportLoading}
                                onClick={async () => {
                                  await fetchReport();
                                }}
                              >
                                <PictureAsPdfIcon></PictureAsPdfIcon>
                              </IconButton>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>

                      <div className={classes.cards}>
                        {senseLink?.length ? (
                          <React.Fragment>
                            <SmallCardsGrid
                              liftStats={liftStats}
                              liftData={liftData}
                              liftDetailsPage={true}
                              periodicalCounterData={periodicalCounterData}
                              liftFlowAvailable={liftFlowAvailable}
                              loading={loading}
                              viewIsCurrent={globalState.viewIsCurrent}
                            />
                          </React.Fragment>
                        ) : (
                          ""
                        )}
                      </div>

                      <>
                        <VisibilitySensor
                          offset={{ top: -100, left: -10000, right: -10000 }}
                          onChange={(e) => {
                            setShowHeader(!e);
                          }}
                        >
                          <Accordion
                            style={{ paddingBottom: 16 }}
                            expanded={detailsAccordionCtrl}
                            onChange={(_event, expanded) =>
                              accordionStateControl(ACCORDION.DETAILS, expanded)
                            }
                            defaultExpanded={expandAccordionOrNot(
                              ACCORDION.DETAILS
                            )}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              style={{ paddingLeft: 0 }}
                            >
                              {" "}
                              {!printing && (
                                <Lift
                                  key={liftData?.id}
                                  lift={liftData}
                                  disableHover={true}
                                />
                              )}
                            </AccordionSummary>

                            <AccordionDetails>
                              {liftData ? (
                                <LiftDetailsDetails
                                  liftData={liftData}
                                  esNumber={
                                    liftData?.sense_link?.[0]?.es_number
                                  }
                                />
                              ) : (
                                <></>
                              )}
                            </AccordionDetails>
                          </Accordion>
                        </VisibilitySensor>

                        {!historicalPeriod && !globalState.userClientLight && !globalState.userMechanic ? (
                          <EventSearchDataProvider>
                            <OpenIssues
                              liftId={liftData?.id}
                              eventKey={[
                                "P-LIFT-EDNL-ST_ALARMSTATE",
                                "P-LIFT-SENSE-VISIT",
                                "P-LIFT-UNAVAILABLE",
                                "P-LIFT-UNAVAILABLE_OUTSIDE_SLA",
                                "P-LIFT-APPROVAL_EXPIRED",
                                "P-LIFT-APPROVAL_REJECTED",
                                "P-LIFT-SENSE-SENSORDATA-FAILURE_SENSOR",
                                "P-LIFT-SERVICECOUNTER_MAINTENANCE_ALERT",
                                "P-SENSE-DISCONNECTED",
                                "P-LIFT-SENSE-DEVICE_COMMUNICATION_UNHEALTHY",
                                "P-LIFT-EDNL-BUTTONTESTFAIL",
                                "P-LIFT-EDNL-AUDIOTESTFAIL",
                                "P-LIFT-EDNL-ST_PERIODIC",
                              ]}
                            ></OpenIssues>
                          </EventSearchDataProvider>
                        ) : (
                          <></>
                        )}

                        <>
                          {/* REALTIME */}
                          {senseLink?.length &&
                          globalState.viewIsCurrent ? (
                            <Accordion
                              expanded={realtimeAccordionCtrl}
                              onChange={(_event, expanded) =>
                                accordionStateControl(
                                  ACCORDION.REALTIME,
                                  expanded
                                )
                              }
                              defaultExpanded={expandAccordionOrNot(
                                ACCORDION.REALTIME
                              )}
                            >
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <h4 className={classes.column}>Realtime</h4>
                                <div className={classes.column}>
                                  <h4 className={classes.subHeading}>
                                    Realtime installatie status
                                  </h4>
                                </div>
                              </AccordionSummary>

                              <AccordionDetails>
                                <LiftDetailsRealTime
                                  width={availableWidth}
                                  liftData={liftData}
                                  liftFlowAvailable={liftFlowAvailable}
                                />
                              </AccordionDetails>
                            </Accordion>
                          ) : (
                            <></>
                          )}

                          {/* HISTORY */}
                          {!printing &&
                          senseLink?.length &&
                          liftFlowAvailable &&
                          !globalState.viewIsCurrent ? (
                            <Accordion
                              ref={historyAccordionRef}
                              expanded={
                                historyAccordionCtrl ||
                                location?.state?.eventCreatedAt
                                  ? true
                                  : false
                              }
                              onChange={(_event, expanded) =>
                                accordionStateControl(
                                  ACCORDION.HISTORY,
                                  expanded
                                )
                              }
                              defaultExpanded={expandAccordionOrNot(
                                ACCORDION.HISTORY
                              )}
                            >
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <h4 className={classes.column}>Historie</h4>
                                <div className={classes.column}>
                                  <h4 className={classes.subHeading}>
                                    Installatie status historie
                                  </h4>
                                </div>
                              </AccordionSummary>

                              <AccordionDetails>
                                <LiftDetailsHistory
                                  fromElevatorMaintenanceChart={
                                    dataFromMaintenanceChart
                                  }
                                  alarmorganisationId={
                                    liftData.alarm_organisation_id
                                  }
                                  width={availableWidth}
                                  esNumber={liftData.sense_link?.[0].es_number}
                                  overwriteDatePickerWithEventDate={
                                    eventDataFromLocation ?? ""
                                  }
                                />
                              </AccordionDetails>
                            </Accordion>
                          ) : (
                            ""
                          )}
                          {/* OVERVIEW */}
                          {
                            // Only when the service counter data is released or when you are a user of the 'admin' group
                            (liftData?.runcounter_data_released ||
                              globalState.userOrganisationType === "admin") &&
                            !globalState.viewIsCurrent ? (
                              <Accordion
                                expanded={overviewAccordionCtrl}
                                onChange={(_event, expanded) =>
                                  accordionStateControl(
                                    ACCORDION.OVERVIEW,
                                    expanded
                                  )
                                }
                                defaultExpanded={expandAccordionOrNot(
                                  ACCORDION.OVERVIEW
                                )}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                >
                                  <h4 className={classes.column}>Overzicht</h4>
                                  <div className={classes.column}>
                                    <h4 className={classes.subHeading}>
                                      Overzichts grafiek
                                    </h4>
                                  </div>
                                </AccordionSummary>

                                <AccordionDetails>
                                  {!triggerChart || loading ? (
                                    <div className={classes.progressIndicator}>
                                      <CircularProgress color="secondary" />
                                    </div>
                                  ) : isEmpty(liftCounterData) ? (
                                    <h3>
                                      <FormattedMessage id="no.chart.data" />
                                    </h3>
                                  ) : (
                                    <>
                                      {availableWidth && (
                                        <div className={classes.singleColumn}>
                                          <ElevatorMaintenance
                                            width={availableWidth}
                                            height={425}
                                            onClickNavFunc={
                                              handleHistoryDetailsNavigationWithMaintenanceData
                                            }
                                            servicecounterInitvalue={
                                              liftData?.servicecounter_initvalue
                                            }
                                            visits={liftVisitsData.items ?? []}
                                            unhealthy={liftEvents?.filter(
                                              (event) =>
                                                event.event_key ===
                                                LIFTEVENT.UNHEALTHY
                                            )}
                                            unavailability={
                                              liftEvents?.filter(
                                                (event) =>
                                                  event.event_key ===
                                                  LIFTEVENT.UNAVAILABLE
                                              ) ?? []
                                            }
                                            data={liftCounterData ?? []}
                                          />
                                        </div>
                                      )}
                                    </>
                                  )}
                                </AccordionDetails>
                              </Accordion>
                            ) : (
                              <></>
                            )
                          }

                          {/* ACTIONS */}
                          {/* Accordion lives inside this component as we needed access to senseModule to show or hide this */}
                          {senseLink?.length &&
                          liftFlowAvailable &&
                          globalState.viewIsCurrent &&
                          liftData.sense_link?.[0]?.es_number ? (
                            <ElevatorCommands
                              expanded={actionsAccordionCtrl}
                              expandIcon={<ExpandMoreIcon />}
                              onChange={(_event, expanded) =>
                                accordionStateControl(
                                  ACCORDION.ACTIONS,
                                  expanded
                                )
                              }
                              defaultExpanded={expandAccordionOrNot(
                                ACCORDION.ACTIONS
                              )}
                            />
                          ) : (
                            <></>
                          )}

                          {/* STATISTICS */}
                          {senseLink?.length &&
                          liftFlowAvailable &&
                          !globalState.viewIsCurrent ? (
                            <Accordion
                              expanded={statisticsAccordionCtrl}
                              onChange={(_event, expanded) =>
                                accordionStateControl(
                                  ACCORDION.STATISTICS,
                                  expanded
                                )
                              }
                              defaultExpanded={expandAccordionOrNot(
                                ACCORDION.STATISTICS
                              )}
                            >
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <h4 className={classes.column}>Statistieken</h4>
                                <div className={classes.column}>
                                  <h4 className={classes.subHeading}>
                                    Actuele statistieken van installatie
                                  </h4>
                                </div>
                              </AccordionSummary>

                              <AccordionDetails>
                                <div className={classes.leftColumn}>
                                  <>
                                    <SenseModuleSummaryProvider
                                      esNumber={
                                        liftData.sense_link?.[0].es_number
                                      }
                                    >
                                      <ElevatorSummary />
                                    </SenseModuleSummaryProvider>

                                    <React.Fragment>
                                      <SenseModuleSeriesProvider>
                                        <div style={{ paddingBottom: 18 }}>
                                          <EnergyUsageChart
                                            liftId={liftId}
                                            from={LiftStatusStorage.startDate}
                                            to={LiftStatusStorage.endDate}
                                          />
                                        </div>
                                      </SenseModuleSeriesProvider>

                                      <ElevatorStatisticsGraph
                                        loading={loading}
                                        liftId={liftId}
                                        from={LiftStatusStorage.startDate}
                                        to={LiftStatusStorage.endDate}
                                        onlyDaily={false}
                                      />
                                    </React.Fragment>
                                  </>
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          ) : (
                            ""
                          )}

                          {/* OWNER ORGANISATIONS */}
                          <Accordion
                            expanded={organisationsAccordionCtrl}
                            onChange={(_event, expanded) =>
                              accordionStateControl(
                                ACCORDION.ORGANISATIONS,
                                expanded
                              )
                            }
                            defaultExpanded={expandAccordionOrNot(
                              ACCORDION.ORGANISATIONS
                            )}
                          >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <h4 className={classes.column}>Organisaties</h4>
                              <div className={classes.column}>
                                <h4 className={classes.subHeading}>
                                  Organisaties betrokken bij installatie
                                </h4>
                              </div>
                            </AccordionSummary>

                            <AccordionDetails>
                              {liftData ? (
                                <LiftDetailsOwnerOrganisations
                                  liftData={liftData}
                                />
                              ) : (
                                <></>
                              )}
                            </AccordionDetails>
                          </Accordion>

                          {/* EMERGENCY_TELEPHONE_SYSTEM */}
                          {globalState.viewIsCurrent ? (
                            <Accordion
                              expanded={emergencyPhoneSystemAccordionCtrl}
                              onChange={(_event, expanded) =>
                                accordionStateControl(
                                  ACCORDION.EMERGENCY_TELEPHONE_SYSTEM,
                                  expanded
                                )
                              }
                              defaultExpanded={expandAccordionOrNot(
                                ACCORDION.EMERGENCY_TELEPHONE_SYSTEM
                              )}
                            >
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <h4 className={classes.column}>
                                  Spreekluistersysteem
                                </h4>
                                <div className={classes.column}>
                                  <h4 className={classes.subHeading}>
                                    Instellingen en gegevens
                                    spreekluistersysteem
                                  </h4>
                                </div>
                              </AccordionSummary>

                              <AccordionDetails>
                                {liftData ? (
                                  <>
                                    <LiftDetailsEmergencyTelephoneSystem
                                      liftData={liftData}
                                    />
                                  </>
                                ) : (
                                  <></>
                                )}
                              </AccordionDetails>
                            </Accordion>
                          ) : (
                            <></>
                          )}

                          {/* SETTINGS */}
                          {globalState.viewIsCurrent ? (
                            <Accordion
                              expanded={settingsAccordionCtrl}
                              onChange={(_event, expanded) =>
                                accordionStateControl(
                                  ACCORDION.SETTINGS,
                                  expanded
                                )
                              }
                              defaultExpanded={expandAccordionOrNot(
                                ACCORDION.SETTINGS
                              )}
                            >
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <h4 className={classes.column}>Instellingen</h4>
                                <div className={classes.column}>
                                  <h4 className={classes.subHeading}>
                                    LiftStatus instellingen voor installatie
                                  </h4>
                                </div>
                              </AccordionSummary>

                              <AccordionDetails>
                                {liftData ? (
                                  <LiftDetailsSettings liftData={liftData} />
                                ) : (
                                  <></>
                                )}
                              </AccordionDetails>
                            </Accordion>
                          ) : (
                            <></>
                          )}

                          {/* MAIN DETAILS LIFT INSTALLATION */}
                          { ( !globalState.userClientLight && !globalState.userMechanic ) ? (
                            <Accordion
                              expanded={mainDataAccordionCtrl}
                              onChange={(_event, expanded) =>
                                accordionStateControl(
                                  ACCORDION.MAINDATA,
                                  expanded
                                )
                              }
                              defaultExpanded={expandAccordionOrNot(
                                ACCORDION.MAINDATA
                              )}
                            >
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <h4 className={classes.column}>
                                  Hoofdgegevens liftinstallatie
                                </h4>

                                <div className={classes.column}>
                                  <h4 className={classes.subHeading}>
                                    Algemene gegevens van de liftinstallatie
                                  </h4>
                                </div>
                              </AccordionSummary>
                              <AccordionDetails>
                                <LiftDetailsInstallation liftData={liftData} />
                              </AccordionDetails>
                            </Accordion>
                          ) : (
                            <></>
                          )}

                          {/* VISITS - Maintenance (Mechanic + Inspector) */}
                          {!globalState.viewIsCurrent ? (
                            <Accordion
                              expanded={visitMaintenanceAccordionCtrl}
                              onChange={(_event, expanded) =>
                                accordionStateControl(
                                  ACCORDION.VISIT_MAINTENANCE,
                                  expanded
                                )
                              }
                              defaultExpanded={expandAccordionOrNot(
                                ACCORDION.VISIT_MAINTENANCE
                              )}
                            >
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <h4 className={classes.column}>
                                  Bezoeken onderhoudspartij
                                </h4>
                                <div className={classes.column}>
                                  <h4 className={classes.subHeading}>
                                    Bezoek details en opmerkingen
                                    onderhoudspartij
                                  </h4>
                                </div>
                              </AccordionSummary>

                              <AccordionDetails>
                                <Visits
                                  loading={loading}
                                  liftId={liftId}
                                  roleFilter="maintenanceParties"
                                  visits={liftEvents?.filter(
                                    (val) => val.event_key === LIFTEVENT.VISIT
                                  )}
                                />
                              </AccordionDetails>
                            </Accordion>
                          ) : (
                            <></>
                          )}

                          {/* VISITS - ApprovingAuthority */}
                          {!globalState.viewIsCurrent ? (
                            <Accordion
                              expanded={visitApprovingAccordionCtrl}
                              onChange={(_event, expanded) =>
                                accordionStateControl(
                                  ACCORDION.VISIT_APPROVING,
                                  expanded
                                )
                              }
                              defaultExpanded={expandAccordionOrNot(
                                ACCORDION.VISIT_APPROVING
                              )}
                            >
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <h4 className={classes.column}>
                                  Bezoeken keurende instantie
                                </h4>
                                <div className={classes.column}>
                                  <h4 className={classes.subHeading}>
                                    Bezoek details en opmerkingen keurende
                                    instantie
                                  </h4>
                                </div>
                              </AccordionSummary>

                              <AccordionDetails>
                                <Visits
                                  loading={loading}
                                  liftId={liftId}
                                  roleFilter="approvingAuthorities"
                                  visits={liftEvents?.filter(
                                    (val) => val.event_key === LIFTEVENT.VISIT
                                  )}
                                />
                              </AccordionDetails>
                            </Accordion>
                          ) : (
                            <></>
                          )}
                          {/* SBCL Details */}
                          {liftData?.details?.sbcl_id ? (
                            <>
                              <Accordion
                                expanded={attachmentsAccordionCtrl}
                                onChange={(_event, expanded) =>
                                  accordionStateControl(
                                    ACCORDION.ATTATCHEMENTS,
                                    expanded
                                  )
                                }
                                defaultExpanded={expandAccordionOrNot(
                                  ACCORDION.ATTATCHEMENTS
                                )}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                >
                                  <h4 className={classes.column}>SBCL</h4>
                                  <div className={classes.column}>
                                    <h4 className={classes.subHeading}>
                                      SBCL Details
                                    </h4>
                                  </div>
                                </AccordionSummary>

                                <AccordionDetails>
                                  <LiftSBCLInstallation />
                                </AccordionDetails>
                              </Accordion>
                            </>
                          ) : (
                            <></>
                          )}

                          {/* ATTACHMENTS */}
                          { ( !globalState.userClientLight && !globalState.userMechanic ) ? (
                            <Accordion
                              expanded={attachmentsAccordionCtrl}
                              onChange={(_event, expanded) =>
                                accordionStateControl(
                                  ACCORDION.ATTATCHEMENTS,
                                  expanded
                                )
                              }
                              defaultExpanded={expandAccordionOrNot(
                                ACCORDION.ATTATCHEMENTS
                              )}
                            >
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <h4 className={classes.column}>Documenten</h4>
                                <div className={classes.column}>
                                  <h4 className={classes.subHeading}>
                                    Documenten en andere bestanden
                                  </h4>
                                </div>
                              </AccordionSummary>

                              <AccordionDetails>
                                {!attachments?.items?.length ? (
                                  <>
                                    <p>Geen documenten voor deze lift</p>
                                  </>
                                ) : (
                                  <>
                                    {attachments.items.map((attachment) => {
                                      return (
                                        <Attachment
                                          key={`attachment-${attachment.id}`}
                                          attachment={attachment}
                                          doDownload={async () => {
                                            const url =
                                              await attachmentsProvider.downloadAttachment(
                                                attachment.id
                                              );

                                            const link =
                                              document.createElement("a");
                                            link.href = url;
                                            link.setAttribute(
                                              "download",
                                              attachment.original_filename
                                            );

                                            // Append to html link element page
                                            document.body.appendChild(link);

                                            // Start download
                                            link.click();

                                            // Clean up and remove the link
                                            link.parentNode.removeChild(link);
                                          }}
                                        />
                                      );
                                    })}
                                  </>
                                )}
                              </AccordionDetails>
                            </Accordion>
                          ) : (
                            <></>
                          )}
                        </>
                      </>
                    </SenseModuleProvider>
                  </React.Fragment>
                ) : (
                  <></>
                )}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  );
};

const LiftDetails = (props) => (
  <LiftDetailsProvider>
    <EventSearchDataProvider>
      <LiftVistsProvider>
        <PeriodicalCounterSumStatsProvider>
          <LiftRunCounterProvider>
            <LiftCounterDataProvider>
              <LiftStatsProvider>
                <AttachmentsProvider>
                  <LiftDetailsInternal props={props} />
                </AttachmentsProvider>
              </LiftStatsProvider>
            </LiftCounterDataProvider>
          </LiftRunCounterProvider>
        </PeriodicalCounterSumStatsProvider>
      </LiftVistsProvider>
    </EventSearchDataProvider>
  </LiftDetailsProvider>
);

export default LiftDetails;
