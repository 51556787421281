import React, { createContext, useState } from "react";
import { useAlert } from "react-alert";
import { useQuery } from "react-query";
import { LiftStatusStorage } from "Utils/Storage";
import { LiftStatusBaseApi } from "Api/Generic";

interface IDashboardStatsPeriodProviderProps {
  children: any;
}

interface ILiftStatusRequest {
  organisationId: number;
  period: 'month' | 'quarter' | 'year' | 'custom';
}

export const DashboardStatsPeriodContext = createContext(null);

const DashboardStatsPeriodProvider = ({ children }: IDashboardStatsPeriodProviderProps) => {
  const alert = useAlert();
  const [request, setRequest] = useState<ILiftStatusRequest>({
    organisationId: LiftStatusStorage?.organisationId,
    period: LiftStatusStorage?.period?.unit
  });

  const fetchDashboardStatsPeriod = async (key, request: ILiftStatusRequest) => {

    const response = await LiftStatusBaseApi.get({
      uri: '/liftstatus/stats/periodical',
      urlParameters: {
        organisationId: request.organisationId ?? 0,
        interval: request.period,
        limit: 4,
      }
    });

    try {
      const data: LiftStatus.Schemas.IStatsOverviewDto = await response?.data;
      return data;
    } catch (error) {
      console.error(error);
      alert.show("Error fetching dashboard periodical stats", { type: "error" });
    }
  };

  const { status, data, error } = useQuery(
    ["dashboard-stats-periodical", request],
    fetchDashboardStatsPeriod,
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  const QueryDashboardStatsPeriod = React.useCallback(() => {
    if (status === "loading") {
      return status;
    }

    if (status === "error") {
      console.error(error);
      alert.show("Error fetching dashboard stats", { type: "error" });
      return status;
    }

    const dataToReturn: LiftStatus.Schemas.IStatsOverviewDto = data;

    return dataToReturn;
  }, [data, status, alert, error]);

  return (
    <DashboardStatsPeriodContext.Provider
      value={{
        response: QueryDashboardStatsPeriod(),
        fetch: setRequest,
      }}
    >
      {children}
    </DashboardStatsPeriodContext.Provider>
  );
};

export default DashboardStatsPeriodProvider;
