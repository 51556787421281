import { Checkbox, FormControlLabel, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import SideMenu from "Components/SideMenu/SideMenu";
import { isEmpty } from "lodash";
import EventNotificationOrganisationFilterProvider, {
  EventNotificationOrganisationFilterContext,
} from "Providers/EventNotificationOrganisationFilterProvider";
import EventSubscriptionProvider, {
  EventSubscriptionContext,
} from "Providers/EventSubscription";
import {
  GlobalStateContext,
  IGlobalState,
} from "Providers/GlobalStateProvider";
import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import classes from "./Subscriptions.module.scss";
import { useAlert } from "react-alert";

const checkboxKeys = [
  "S-LIFT-ALARMCALL",
  "P-LIFT-EDNL-ST_ALARMSTATE",
  "S-LIFT-ALARMCALL_NOTANSWERED",
  "S-LIFT-RUNCOUNTER",
  "P-LIFT-SERVICECOUNTER_MAINTENANCE_WARNING",
  "P-LIFT-SERVICECOUNTER_MAINTENANCE_ALERT",
  "S-LIFT-SERVICECOUNTER_MAINTENANCE_RESET",
  "P-LIFT-APPROVAL_REJECTED",
  "P-LIFT-APPROVAL_EXPIRED",
  "P-LIFT-EDNL-ST_BATTERY",
  "P-LIFT-SENSE-SENSORDATA-FAILURE_SENSOR",
  "P-LIFT-EDNL-ST_MAINSSTATUS",
  "P-LIFT-EDNL-ST_BATTERYDEFECT",
  "P-LIFT-SENSE-VISIT",
  "P-THD-LIFT-ALARMCALLS_HOURLY",
  "P-THD-LIFT-RUNCOUNTER_WEEKLY",
  "P-LIFT-UNAVAILABLE",
  "P-THD-LIFT-ALARMCALLS_WEEKLY",
  "P-SENSE-DISCONNECTED",
  "P-THD-LIFT-SMS-SMS_WEEKLY",
  "P-LIFT-SENSE-DEVICE_COMMUNICATION_UNHEALTHY",
  "P-DATA-CONNECTION-DISCONNECTED"
];

const combinedCheckboxKeys = [
  {
    label: "event.P-DATACALLS-PER-WEEK",
    keys: [
      "P-THD-LIFT-EDNL-DATACALLS_WEEKLY",
      "P-THD-LIFT-GENERIC-DATACALLS_WEEKLY",
    ],
  },
  {
    label: "event.S-LIFT-GENERIC-DATACALL",
    keys: [
      "S-LIFT-GENERIC-DATACALL",
      "S-LIFT-EDNL-DATACALL",
      "S-LIFT-SMS-DATACALL",
    ],
  },
  {
    label: "event.P-LIFT-EDNL-ST_PERIODIC",
    keys: [
      "P-LIFT-EDNL-ST_PERIODIC",
      "P-LIFT-GENERIC-ST_PERIODIC",
      "P-LIFT-SMS-ST_PERIODIC",
    ],
  },
  {
    label: "event.S-LIFT-LOG",
    keys: [
      "S-LIFT-LOG",
      "S-LIFT-LOG-OTHER",
      "S-LIFT-LOG-VISIT_PLANNED",
      "S-LIFT-LOG-VISIT_DONE",
    ],
  },
];
interface IEventsFilterCheckboxState {
  [key: string]: boolean;
}
const InnerSubscriptions = () => {
  const [loading, setLoading] = useState(false);
  const [renderCheckboxes, setRenderCheckboxes] = useState(false);
  const [usersReportSubs, setUsersReportSubs] = useState([]);
  const [usersNotificationSubs, setUsersNotificationSubs] = useState([]);
  const [globalState] =
    useContext<[IGlobalState, React.Dispatch<any>]>(GlobalStateContext);
  const intl = useIntl();
  const eventSubscriptionProvider = useContext(EventSubscriptionContext);
  const eventNotificationOrganisationFilterProvider = useContext(
    EventNotificationOrganisationFilterContext
  );
  const [eventsReportCheckboxesState, setEventsReportsCheckboxesState] =
    useState<IEventsFilterCheckboxState>({});
  const [
    eventsNotificationCheckboxesState,
    setEventsNotificationsCheckboxesState,
  ] = useState<IEventsFilterCheckboxState>({});
  const [organisations, setOrganisations] = useState<
    LiftStatus.Schemas.IOrganisation[]
  >([]);
  const [, setSearchQuery] = React.useState("");
  const [selectedOrganisation, setSelectedOrganisation] =
    React.useState<LiftStatus.Schemas.IOrganisation>();
  const [filterOnOrganisations, setFilterOnOrganisations] = useState<
    LiftStatus.Schemas.IOrganisation[]
  >([]);
  const [subedNotificationObjs, setSubedNotificationObjs] = useState([]);
  const alert = useAlert();



  const renderEventReportCheckboxes = () => {

    const combinedCheckboxes = combinedCheckboxKeys.map(
      (combinedCheckboxKey) => {
        const matchingCheckboxState = Object.entries(
          eventsReportCheckboxesState
        ).filter(([key]) => combinedCheckboxKey.keys.includes(key));

        let checkboxState = false;

        // We will only do this if matchingCheckboxState is not empty, because running .every on empty arrays always returns true
        // see https://stackoverflow.com/questions/34137250/why-does-array-prototype-every-return-true-on-an-empty-array
        //
        if (!isEmpty(matchingCheckboxState)) {
          checkboxState = matchingCheckboxState.every(
            ([_key, value]) => value === true
          );
        }

        return (
          <FormControlLabel
            className={classes.label}
            style={{ textAlign: "left" }}
            key={combinedCheckboxKey.label}
            label={intl.formatMessage({ id: combinedCheckboxKey.label })}
            control={
              <Checkbox
                className={classes.checkbox}
                checked={!!checkboxState}
                disabled={loading}
                onChange={() => {
                  const newCheckboxState: IEventsFilterCheckboxState = {};

                  combinedCheckboxKey.keys.forEach(
                    (key) => {
                      (newCheckboxState[key] = !checkboxState)
                    }
                  );
                  setEventsReportsCheckboxesState({
                    ...eventsReportCheckboxesState,
                    ...newCheckboxState,
                  });

                  Object.keys(newCheckboxState).forEach(key => {
                    eventCheckboxClicked(key, "report")
                  })
                }}
              />
            }
          ></FormControlLabel>
        );
      }
    );

    const normalCheckboxes = checkboxKeys.map((checkboxKey) => {
      return (
        <FormControlLabel
          className={classes.label}
          key={checkboxKey}
          control={
            <Checkbox
              checked={!!eventsReportCheckboxesState[checkboxKey]} // we use a double negation here to convert the initial undefined to false
              disabled={loading}
              onChange={() => {
                setEventsReportsCheckboxesState({
                  ...eventsReportCheckboxesState,
                  [checkboxKey]: !eventsReportCheckboxesState[checkboxKey],
                })
                eventCheckboxClicked(checkboxKey, "report")
              }}
            />
          }
          label={intl.formatMessage({ id: `event.${checkboxKey}` })}
          labelPlacement="end"
          style={{ textAlign: "left" }}
        />
      );
    });

    return [...normalCheckboxes, ...combinedCheckboxes];
  };

  const renderEventNotificationCheckboxes = () => {

    const combinedCheckboxes = combinedCheckboxKeys.map(
      (combinedCheckboxKey) => {
        const matchingCheckboxState = Object.entries(
          eventsNotificationCheckboxesState
        ).filter(([key]) => combinedCheckboxKey.keys.includes(key));

        let checkboxState = false;

        // We will only do this if matchingCheckboxState is not empty, because running .every on empty arrays always returns true
        // see https://stackoverflow.com/questions/34137250/why-does-array-prototype-every-return-true-on-an-empty-array
        //
        if (!isEmpty(matchingCheckboxState)) {
          checkboxState = matchingCheckboxState.every(
            ([_key, value]) => value === true
          );
        }

        return (
          <FormControlLabel
            className={classes.label}
            style={{ textAlign: "left" }}
            key={combinedCheckboxKey.label}
            label={intl.formatMessage({ id: combinedCheckboxKey.label })}
            control={
              <Checkbox
                className={classes.checkbox}
                checked={!!checkboxState}
                disabled={loading}
                onChange={() => {
                  const newCheckboxState: IEventsFilterCheckboxState = {};

                  combinedCheckboxKey.keys.forEach(
                    (key) => {
                      (newCheckboxState[key] = !checkboxState)
                    }
                  );
                  setEventsNotificationsCheckboxesState({
                    ...eventsNotificationCheckboxesState,
                    ...newCheckboxState,
                  });

                  Object.keys(newCheckboxState).forEach(key => {
                    eventCheckboxClicked(key, "realtime")
                  })
                }}
              />
            }
          ></FormControlLabel>
        );
      }
    );
    const normalCheckboxes = checkboxKeys.map((checkboxKey) => {
      return (
        <FormControlLabel
          className={classes.label}
          key={checkboxKey}
          control={
            <Checkbox
              checked={!!eventsNotificationCheckboxesState[checkboxKey]}
              disabled={loading}
              onChange={() => {
                setEventsNotificationsCheckboxesState({
                  ...eventsNotificationCheckboxesState,
                  [checkboxKey]: !eventsNotificationCheckboxesState[checkboxKey],
                });
                eventCheckboxClicked(checkboxKey, "realtime")
              }}
            />
          }
          label={intl.formatMessage({ id: `event.${checkboxKey}` })}
          labelPlacement="end"
          style={{ textAlign: "left" }}
        />
      );
    });

    return [...normalCheckboxes, ...combinedCheckboxes];
  };

  const eventCheckboxClicked = (
    checkboxKey: string,
    type: "report" | "realtime"
  ) => {
    // Check mark true or false
    // depending on type of event (realtime or report)
    //
    let keyValue;
    // here we set the state of the checkbox depending on the type
    //
    if (type === "report") {
      keyValue = !eventsReportCheckboxesState[checkboxKey];
    } else {
      keyValue = !eventsNotificationCheckboxesState[checkboxKey];
    }

    // If checkmark is true
    //
    if (keyValue) {
      setLoading(true);
      // call subscribe
      eventSubscriptionProvider
        .post({ type, eventKey: checkboxKey })
        .then((response) => {
          if (type === "report") {
            setUsersReportSubs((currentState) => [...currentState, response]);
          } else {
            setUsersNotificationSubs((currentState) => [...currentState, response]);
          }
        })
        .catch((e) => console.error(e))
        .finally(() => setLoading(false));

    } else {
      // checkmark is false
      setLoading(true);
      // call unsubscribe
      // The reason we use localState here is because
      // we needed to call a get on all the subs and find their ID to be able to
      // unsub, sadly we can't just use the event_key that we already have but
      // we need to cross reference already subbed events to get their ID to unsub.
      //
      // we also need to know if it comes from the report subs or notification subs
      //
      if (usersNotificationSubs || usersReportSubs) {
        let keyId;
        if (type === "report") {
          keyId = usersReportSubs.find(
            (item) => item?.event_key === checkboxKey
          )?.id;
          // Here we remove the local state sub item so we find the new one next time
          // we delete the same key
          //
          if (keyId) {
            setUsersReportSubs((currentState) =>
              currentState.filter((item) => item.id !== keyId)
            );
          }

        } else {
          keyId = usersNotificationSubs.find(
            (item) => item.event_key === checkboxKey
          )?.id;

          if (keyId) {
            setUsersNotificationSubs((currentState) =>
              currentState.filter((item) => item.id !== keyId)
            );
          }
        }

        eventSubscriptionProvider
          .delete({ id: keyId })
          .catch((e) => console.error(e))
          .finally(() => setLoading(false));

      }
    }
  };

  const fetchAllEventSubs = async () => {
    setLoading(true);
    let tempReportCheckboxState: IEventsFilterCheckboxState = {};
    let tempNotificationCheckboxState: IEventsFilterCheckboxState = {};
    const notificationSubs = [];
    const reportSubs = [];
    // This will get all the subbed events a user has
    // and populate the state with them so we can get their ID's to delete the sub
    // and to set the checkboxes
    //
    eventSubscriptionProvider
      .get()
      .then((response) => {
        response.items.each((item) => {
          // Reports go into report
          // Realtime go into notifications
          //
          if (item.type === "report") {
            reportSubs.push(item);
            tempReportCheckboxState = {
              ...tempReportCheckboxState,
              [item.event_key]: true,
            };
          } else {
            notificationSubs.push(item);
            tempNotificationCheckboxState = {
              ...tempNotificationCheckboxState,
              [item.event_key]: true,
            };
          }
        });
        // Set the users already subbed keys into local state
        // to be used for retrieving the ID's of the events to call a delete
        //
        setUsersNotificationSubs(notificationSubs);
        setUsersReportSubs(reportSubs);
      })
      .catch((e) => console.error(e))
      .finally(() => {
        setEventsReportsCheckboxesState(tempReportCheckboxState);
        setEventsNotificationsCheckboxesState(tempNotificationCheckboxState);
        setLoading(false);
        if (eventsReportCheckboxesState) {
          setRenderCheckboxes(true);
        }
      });
  };

  const addOuFilter = (ou: LiftStatus.Schemas.IOrganisation) => {
    setLoading(true);

    const alreadySubbed = filterOnOrganisations.find(orgOu => orgOu.id === ou.id);
    if (alreadySubbed) {
      alert.show('Organisation already selected', { type: 'error' });
      setLoading(false);
      return;
    }
    // call api to delete og filter
    //
    eventNotificationOrganisationFilterProvider
      .post({ ouId: ou.id })
      .then((response) => {
        // Set newely subbed ou to notificationObjs so we can use id to delete
        //
        setSubedNotificationObjs([...subedNotificationObjs, response]);
        setOrganisations((currentState) => currentState.filter((item) => item.id !== ou.id));

        // Add og to local state
        setFilterOnOrganisations([
          ...filterOnOrganisations,
          selectedOrganisation,
        ]);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  const removeOuFilter = (ou: LiftStatus.Schemas.IOrganisation) => {

    // We have all the subed ou's notification objs in a localstate, they each have a
    // unique Id we need to call delete with rather then an OuID
    // Swagger doesn't show this well or typings
    // hence the comment here.
    //
    const subedIdToDelete = subedNotificationObjs.find(
      (item) => item.organisation_id === ou.id
    );
    if (subedIdToDelete) {
      setLoading(true);
      // call api to delete og filter
      //
      eventNotificationOrganisationFilterProvider
        .delete({ id: subedIdToDelete.id })
        .then(() => {
          // sort and add the ou back to the ou list so user can re-click it
          //
          const sortedOrganisations = [...organisations, ou].sort((a, b) => a.name.localeCompare(b.name));
          setOrganisations([...sortedOrganisations]);
          // remove og from local state
          setFilterOnOrganisations((currentState) =>
            currentState.filter((item) => item.id !== ou.id)
          );
        })
        .catch((e) => console.error(e))
        .finally(() => setLoading(false));
    }

  };

  const getOuFilters = () => {
    setLoading(true);

    // call api to delete ou filter
    //
    eventNotificationOrganisationFilterProvider
      .get()
      .then((response) => {
        // Add response to local state and ou to local ou state list
        // the response holds the ID (every ou subbed to has a unique ID) needed to call delete on thats why we save it
        // rather then just use OU id to delete.
        //
        setSubedNotificationObjs(response.items);
        const tempOgList = [];
        organisations.forEach((og) => {
          response.items.forEach((ogFromRes) => {
            if (ogFromRes.organisation_id === og.id) {
              tempOgList.push(og);
            }
          });
        });
        if (tempOgList.length > 0) {
          const tempOuList = organisations.filter(ou => !tempOgList.includes(ou))
          setOrganisations(tempOuList);
          setFilterOnOrganisations([...filterOnOrganisations, ...tempOgList]);
        }
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchAllEventSubs();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedOrganisation) {
      addOuFilter(selectedOrganisation);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganisation]);

  /**
   * Handle API response from Lifts route
   */
  useEffect(() => {
    if (!isEmpty(globalState.organisationList) && isEmpty(organisations)) {
      if (globalState.organisationList?.length > 0) {
        setOrganisations([...globalState.organisationList]);
      }
    }

    if (organisations) {
      getOuFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalState.organisationList, organisations]);

  return (
    <div className={classes.root}>
      <SideMenu />
      <div className={classes.wrapper}>
        <h2>Meldingen</h2>
        <div className={classes.checkBoxesWrapper}>
          <div className={classes.eventCheckboxesContainer}>
            <h3>Notificaties van events</h3>
            {renderCheckboxes ? renderEventNotificationCheckboxes() : ""}
          </div>
          <div className={classes.eventCheckboxesContainer}>
            <h3>Rapportage van events</h3>
            {renderCheckboxes ? renderEventReportCheckboxes() : ""}
          </div>

        </div>

        <div className={classes.organisations}>
          <h2>Notificatie filtering</h2>
          <h3>
            Stuur alleen notificaties indien de lift ook een relatie heeft met
            tenminste één van deze organisaties:
          </h3>
          <div>
            {filterOnOrganisations.length > 0 && (
              <div className={classes.ogNames}>
                {filterOnOrganisations.map((og) => (
                  <span key={og?.id} className={classes.ogRow}>
                    {og?.name}
                    <button onClick={() => removeOuFilter(og)}>X</button>
                  </span>
                ))}
              </div>
            )}
            {organisations.length > 0 && (
              <Autocomplete
                options={organisations}
                className={classes.auto}
                classes={{
                  option: classes.option,
                }}
                key={selectedOrganisation ? "loaded" : "not loaded"} // Small hack to force re-render of default value
                autoHighlight
                getOptionLabel={(option: LiftStatus.Schemas.IOrganisation) =>
                  option.name
                }
                renderOption={(option: LiftStatus.Schemas.IOrganisation) =>
                  option.name
                }
                onChange={(
                  _event,
                  newValue: LiftStatus.Schemas.IOrganisation
                ) => {
                  if (newValue) {
                    setSelectedOrganisation({ ...newValue });
                  } else {
                    setSelectedOrganisation(null);
                  }
                }}
                onInputChange={(_event, newInputValue) => {
                  setSearchQuery(newInputValue);
                }}
                getOptionSelected={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    variant="standard"
                    {...params}
                    label="Kies een organisatie"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const Subscriptions = () => {
  return (
    <EventNotificationOrganisationFilterProvider>
      <EventSubscriptionProvider>
        <InnerSubscriptions />
      </EventSubscriptionProvider>
    </EventNotificationOrganisationFilterProvider>
  );
};

export default Subscriptions;
