import { Button, Divider, IconButton, Menu, MenuItem } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { isFunction } from "lodash";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { LiftStatusStorage } from "Utils/Storage";
import classes from "./PeriodSelector.module.scss";
import {
  EGlobalStateCases,
  GlobalStateContext,
  IGlobalState,
} from "Providers/GlobalStateProvider";

enum LabelType {
  current = 0,
  previous = -1,
  currentView = 2,
}

export type TTimeUnit = "month" | "quarter" | "year" | "custom";
export interface ITimeSelection {
  unit: TTimeUnit;
  period: number;
  label?: string;
}

export interface IDateRange {
  from: Date;
  to: Date;
}

interface PeriodSelectorProps {
  onlyUnits?: boolean;
  onDateChange: (dateRange: IDateRange) => void;
}

const PeriodSelector = (props: PeriodSelectorProps) => {
  const [periodLabel, setPeriodLabel] = useState<string>(null);
  const intl = useIntl();
  const [globalState, globalDispatch] =
    useContext<[IGlobalState, React.Dispatch<any>]>(GlobalStateContext);
  const defaultTimeSelection: ITimeSelection = {
    unit: "year",
    period: LiftStatusStorage?.period?.period ?? LabelType.current,
    label: "",
  };
  const [userAbleToModifyTime, setUserAbleToModifyTime] = useState(true);

  const [dateRange, setDateRange] = useState<{ from: Date; to: Date }>();

  const [timeSelection, setTimeSelection] = useState<ITimeSelection>();

  const createLabel = useCallback(
    (selection: ITimeSelection) => {
      const { onlyUnits } = props;

      if (onlyUnits) {
        switch (selection.unit) {
          case "month":
            setPeriodLabel(intl.formatMessage({ id: "months" }));
            break;
          case "quarter":
            setPeriodLabel(intl.formatMessage({ id: "quarters" }));
            break;
          case "year":
            setPeriodLabel(intl.formatMessage({ id: "years" }));
            break;
        }
      } else {
        if (selection.period === LabelType.current) {
          switch (selection.unit) {
            case "month":
              setPeriodLabel(intl.formatMessage({ id: "current.month" }));
              break;
            case "quarter":
              setPeriodLabel(intl.formatMessage({ id: "current.quarter" }));
              break;
            case "year":
              setPeriodLabel(intl.formatMessage({ id: "current.year" }));
              break;
          }
        } else if (selection.period === LabelType.previous) {
          switch (selection.unit) {
            case "month":
              setPeriodLabel(intl.formatMessage({ id: "previous.month" }));
              break;
            case "quarter":
              setPeriodLabel(intl.formatMessage({ id: "previous.quarter" }));
              break;
            case "year":
              setPeriodLabel(intl.formatMessage({ id: "previous.year" }));
              break;
          }
        }
      }
    },
    [intl, props]
  );

  // Set inital time selection
  //
  React.useEffect(() => {
    const currentLocalStoragePeriods = JSON.parse(
      localStorage.getItem("liftstatus-period")
    );

    if (
      currentLocalStoragePeriods &&
      currentLocalStoragePeriods.label !== defaultTimeSelection.label
    ) {
      setTimeSelection(currentLocalStoragePeriods);
    } else {
      setTimeSelection(defaultTimeSelection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Change label when time selection changes
  //
  React.useEffect(() => {
    if (timeSelection) {
      createLabel(timeSelection);
    }
  }, [timeSelection, createLabel, dateRange]);

  React.useEffect(() => {
    const currentLocalStoragePeriods = JSON.parse(
      localStorage.getItem("liftstatus-period")
    );

    if (currentLocalStoragePeriods) {
      localStorage.setItem(
        "liftstatus-period",
        JSON.stringify({ ...currentLocalStoragePeriods, label: periodLabel })
      );
    }
  }, [periodLabel, dateRange, timeSelection]);

  // Call the period selector props function
  //
  React.useEffect(() => {
    if (dateRange) {
      if (isFunction(props.onDateChange)) {
        props.onDateChange(dateRange);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  // Change from and to dates when time selection changes
  //
  React.useEffect(() => {
    if (timeSelection) {
      const today = moment();

      switch (timeSelection.unit) {
        case "month":
        case "year":
        case "quarter":
          today.add(timeSelection.period, timeSelection.unit);
          setDateRange({
            from: today.startOf(timeSelection.unit).toDate(),
            to: today.endOf(timeSelection.unit).toDate(),
          });
          break;
      }

      // Store in local storage
      //
      LiftStatusStorage.period = timeSelection;
    }
  }, [timeSelection]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const changePeriod = React.useCallback(
    (direction) => {
      const newTimeSelection = { ...timeSelection };

      newTimeSelection.period += direction;

      setTimeSelection(newTimeSelection);
    },
    [timeSelection, setTimeSelection]
  );

  const changeTimeFlowToCurrent = useCallback(() => {
    globalDispatch({
      type: EGlobalStateCases.switchViewToCurrent,
      payload: true,
    });

    handleClose();
  }, [globalDispatch]);

  const changeTimeSelection = (unit: TTimeUnit, offset: number) => {
    globalDispatch({
      type: EGlobalStateCases.switchViewToCurrent,
      payload: false,
    });

    const newTimeSelection: ITimeSelection = {
      unit: unit,
      period: offset,
    };

    setTimeSelection(newTimeSelection);
    handleClose();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getMenu = () => {
    const { onlyUnits } = props;

    if (!userAbleToModifyTime) {
      return;
    }
    if (onlyUnits) {
      return (
        <Menu
          id="period-selection-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => changeTimeSelection("month", 0)}>
            <FormattedMessage id="months" />
          </MenuItem>
          <MenuItem onClick={() => changeTimeSelection("quarter", 0)}>
            <FormattedMessage id="quarters" />
          </MenuItem>
          <MenuItem onClick={() => changeTimeSelection("year", 0)}>
            <FormattedMessage id="years" />
          </MenuItem>
        </Menu>
      );
    }

    return (
      <Menu
        id="period-selection-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => changeTimeFlowToCurrent()}>
          <FormattedMessage id="current.situation" />
        </MenuItem>

        <Divider />

        <MenuItem
          onClick={() => changeTimeSelection("month", LabelType.current)}
        >
          <FormattedMessage id="current.month" />
        </MenuItem>
        <MenuItem
          onClick={() => changeTimeSelection("month", LabelType.previous)}
        >
          <FormattedMessage id="previous.month" />
        </MenuItem>
        <MenuItem
          onClick={() => changeTimeSelection("quarter", LabelType.current)}
        >
          <FormattedMessage id="current.quarter" />
        </MenuItem>
        <MenuItem
          onClick={() => changeTimeSelection("quarter", LabelType.previous)}
        >
          <FormattedMessage id="previous.quarter" />
        </MenuItem>
        <MenuItem
          onClick={() => changeTimeSelection("year", LabelType.current)}
        >
          <FormattedMessage id="current.year" />
        </MenuItem>
        <MenuItem
          onClick={() => changeTimeSelection("year", LabelType.previous)}
        >
          <FormattedMessage id="previous.year" />
        </MenuItem>
      </Menu>
    );
  };

  const getPeriodDescription = () => {
    return (
      <>
        {globalState.viewIsCurrent ? (
          <FormattedMessage id="current.situation" />
        ) : dateRange &&
          timeSelection &&
          ![LabelType.current, LabelType.previous].includes(
            LiftStatusStorage?.period.period
          ) ? (
          <>
            {timeSelection.unit === "month"
              ? moment(LiftStatusStorage.startDate).format("MMM YYYY")
              : moment(LiftStatusStorage.startDate).format("MMM YYYY") +
                " t/m " +
                moment(LiftStatusStorage.endDate).format("MMM YYYY")}
          </>
        ) : (
          <>{LiftStatusStorage?.period?.label ?? periodLabel}</>
        )}
      </>
    );
  };

  // handle user roles and it's affect on the time selection
  //
  useEffect(() => {
    // In case the user is a "Client light" or "Monteur" we want to show the current situation
    // and disable the ability to change dates.
    //
    if (globalState.userClientLight || globalState.userMechanic) {
      changeTimeFlowToCurrent();
      // Reason I made this a state obj is for the future adaptation of more user roles.
      // this will be read in the UI aspect to remove / show items.
      //
      setUserAbleToModifyTime(false);
    }
  }, [
    globalState.userRoles,
    globalState.userClientLight,
    globalState.userMechanic,
    changeTimeFlowToCurrent,
  ]);

  return (
    <>
      <div className={classes.buttonGroup}>
        {!props.onlyUnits && !globalState.viewIsCurrent && (
          <IconButton onClick={() => changePeriod(-1)}>
            <ChevronLeftIcon />
          </IconButton>
        )}

        {userAbleToModifyTime ? (
          <Button
            variant="outlined"
            className={classes.rangeBtn}
            onClick={handleClick}
          >
            {getPeriodDescription()}
          </Button>
        ) : (
          <></>
        )}

        {!props.onlyUnits &&
        !globalState.viewIsCurrent &&
        timeSelection &&
        LiftStatusStorage?.period?.period < 0 ? (
          <IconButton onClick={() => changePeriod(1)}>
            <ChevronRightIcon />
          </IconButton>
        ) : (
          []
        )}
      </div>
      {getMenu()}
    </>
  );
};

export default PeriodSelector;
