/* eslint-disable react/no-children-prop */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, Context, useRef, useMemo } from "react";
import { isNumber } from "lodash";
import MomentUtils from "@date-io/moment";
import moment from "moment";

/**
 * Context bridge to re-use outer context inside a new (canvas) context
 */
export function useContextBridge(...contexts: Array<React.Context<any>>) {
  const cRef = useRef<Array<Context<any>>>([]);
  cRef.current = contexts.map((context) => React.useContext(context));
  return useMemo(
    () => ({ children }: { children: ReactElement<any> }) =>
      contexts.reduceRight(
        (acc, Context, i) => (
          <Context.Provider value={cRef.current[i]} children={acc} />
        ),
        children
      ),
    []
  );
}

/**
 * Generate random colors
 */
export function randomColor() {
  return "#" + (((1 << 24) * Math.random()) | 0).toString(16);
}

/**
 * Create fixed colors for sensor ID's
 * @param value the value of the sensor
 * @param sensorType the type of sensor
 */
export function sensorValueColorCode(value, sensorType) {
  let result = "#ffffff";

  const switchValue = `${value}`;
  switch (switchValue) {
    case "+":
      result = "#E1BEE7";
      break;
    case "-":
      result = "#C5CAE9";
      break;
    case "U":
      result = "#D1C4E9";
      break;
    case "D":
      result = "#B2DFDB";
      break;
    case "O":
      result = "#C8E6C9";
      break;
    case "C":
      result = "#F0F4C3";
      break;
    case "X":
      result = "#f4c3c3";
      break;
  }

  const number = parseInt(value, 10);

  if (!isNaN(number) && isNumber(number)) {
    if ((!sensorType || sensorType !== 514) && isNumber(value)) {
      result = "#C5E1A5";
    } else if ( sensorType === 514) {
      if (number % 2 === 0) {
        result = "#E0F2F1";
      } else {
        result = "#B2DFDB";
      }
    } else if ( [103, 105, 550, 210, 212 ].includes(sensorType) ) {
      if ( number === 0 ) {
        result = "#C5E1A5";
      } else if ( number === 1 ) {
        result = "#f4c3c3";
      }
    } else if ( [50, 104, 504, 524 ].includes(sensorType) ) {
      if ( number === 0 ) {
        result = "#f4c3c3";
      } else if ( number === 1 ) {
        result = "#C5E1A5";
      }
    }
  }

  return result;
}

/**
 * Shows a human readable description on how long ago a timestamp was
 */
export const timeAgoFromMilliseconds = (ts: number, dontHumanize?: boolean) => {

  if (dontHumanize) {
    return moment.duration(ts - +new Date(), "milliseconds").humanize(false);
  } else {
    return moment.duration(ts - +new Date(), "milliseconds").humanize(true);
  }
};

/**
 * Provide a translation for a sensor ID
 */
export function translateSensorId(key: number) {
  let result = "Unknown";
  switch (key) {
    case 50:
      result = "Verbinding met sturing";
      break;
    case 103:
      result = "Onbeschikbaar";
      break;
    case 104:
      result = "Bezoek";
      break;
    case 105:
      result = "Beschikbaar"; // was: "Storing sensor"; eigenlijk ONbeschikbaar, puur voor labeling in Swimlane inverted
      break;
    case 210:
      result = "Verzamel storing";
      break;
    case 211:
      result = "Verzamel melding";
      break;
    case 212:
      result = "Technisch beschikbaar"; // eigenlijk ONbeschikbaar, puur voor labeling in Swimlane inverted
      break;
    case 500:
      result = "Bewegingstoestand";
      break;
    case 501:
      result = "Huidige stopplaats (open deuren)";
      break;
    case 502:
      result = "Deur positie voor";
      break;
    case 503:
      result = "Deur positie achter";
      break;
    case 504:
      result = "Veiligheidslijn";
      break;
    case 505:
      result = "Deurlijn";
      break;
    case 506:
      result = "Temperatuur regelaar";
      break;
    case 513:
      result = "Foutmeldingen";
      break;
    case 514:
      result = "Laatst gepasseerde stopplaats";
      break;
    case 515:
      result = "Huidige stopplaats";
      break;
    case 524:
      result = "Verbinding met sturing gezond";
      break;
    case 550:
      result = "Na oproep geen reactie";
      break;
    case 590:
      result = "Besturing";
      break;
    default:
      result = `${key}`;
      break;
  }
  return result;
}

/**
 * Figure out the number of weeks in this year
 * @param year number
 * source: https://stackoverflow.com/questions/13796950/javascript-weeks-per-year?lq=1
 */
export function getISOWeeks(year: number) {
  const d = new Date(year, 0, 1);
  const isLeap = new Date(year, 1, 29).getMonth() === 1;
  // check for a Jan 1 that's a Thursday or a leap year that has a
  // Wednesday jan 1. Otherwise it's 52
  return d.getDay() === 4 || (isLeap && d.getDay() === 3) ? 53 : 52;
}

/**
 * Formatter to fix the title in the date picker dialog
 */
export class LocalizedMomentUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return moment(date).format("dd MMMM yyyy");
  }
}
