import { CircularProgress, makeStyles } from "@material-ui/core";
import * as d3 from "d3";
import { BrushBehavior } from "d3";
import {
  each,
  filter,
  first,
  get,
  includes,
  isEqual,
  isNull,
  isNumber,
  isObject,
  map,
  size,
} from "lodash";
import moment from "moment";
import { useSenseModuleHistory } from "Providers/SenseModuleHistoryProvider";
import { IGetModuleSensorData } from "Providers/SenseModuleProvider";
import React from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useImmer } from "use-immer";
import { sensorValueColorCode, translateSensorId } from "Utils/Helpers";
import { v4 as uuidv4 } from "uuid";
import styles from "./ElevatorHistorySwimlanes.module.scss";

export interface IHistorySwimlaneItemPagination {
  ts: Date;
  val: string | number;
  sensor_id: number;
}

export interface IHistorySwimlaneLane {
  id: number;
  label: string;
  sensor: number;
}

export interface IHistorySwimlaneItem {
  id: number;
  lane: number;
  start: number;
  realStart: number;
  end: number;
  realEnd: number;
  value: number | string;
  className: string;
  sensor: number;
  desc: string;
  color?: string;
  next: IHistorySwimlaneItemPagination | null;
  prev: IHistorySwimlaneItemPagination | null;
}

// Styling for the component goes here
//
const componentStyles = makeStyles(() => ({
  chart: {
    minHeight: "200px",
    width: "100%",
    position: "relative",
  },
  loading: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100px",
  },
}));

const enabledSensors = [
  { id: 500, enabled: true },
  { id: 501, enabled: true },
  { id: 515, enabled: true },
  { id: 514, enabled: true },
  { id: 502, enabled: true },
  { id: 503, enabled: true }, // can be toggled disabled when there is no backdoor
  { id: 104, enabled: true }, // Bezoek
  { id: 105, enabled: true },
  { id: 212, enabled: true },
  { id: 550, enabled: true },
  { id: 524, enabled: true },
];

// What interval's do we support
//
const availableIntervals = [
  { id: 0, unit: "hours", amount: 1, display: "Elk uur" },
  { id: 1, unit: "minutes", amount: 30, display: "Elke 30 minuten" },
  { id: 2, unit: "minutes", amount: 15, display: "Elke 15 minuten" },
  { id: 3, unit: "minutes", amount: 5, display: "Elke 5 minuten" },
];

/**
 * History graph of elevator events
 */
const ElevatorHistorySwimlanes = ({
  periodEndDate,
  width,
  dateRangeProps,
  eventStartDate,
}: {
  periodEndDate: any;
  width: number;
  dateRangeProps: any;
  eventStartDate?: string;
}) => {
  // Refs
  //
  const mainHorizontalScale = React.useRef<d3.ScaleTime<number, number>>();
  const miniHorizontalScale = React.useRef<d3.ScaleTime<number, number>>();
  const mainVerticalScale = React.useRef<d3.ScaleLinear<number, number>>();
  const miniVerticalScale = React.useRef<d3.ScaleLinear<number, number>>();
  const rowHighlight =
    React.useRef<d3.Selection<SVGRectElement, unknown, null, undefined>>();
  const mainTimeIndicator =
    React.useRef<d3.Selection<SVGLineElement, unknown, null, undefined>>();
  const miniTimeIndicator =
    React.useRef<d3.Selection<SVGLineElement, unknown, null, undefined>>();
  const mainTimeDisplay =
    React.useRef<d3.Selection<HTMLDivElement, unknown, null, undefined>>();
  const miniTimeDisplay =
    React.useRef<d3.Selection<HTMLDivElement, unknown, null, undefined>>();

  const brush = React.useRef<BrushBehavior<any>>();

  const miniStartTimeIndicator =
    React.useRef<d3.Selection<SVGTextElement, unknown, null, undefined>>();
  const miniEndTimeIndicator =
    React.useRef<d3.Selection<SVGTextElement, unknown, null, undefined>>();
  const mainStartTimeIndicator =
    React.useRef<d3.Selection<SVGTextElement, unknown, null, undefined>>();
  const mainEndTimeIndicator =
    React.useRef<d3.Selection<SVGTextElement, unknown, null, undefined>>();
  const miniDurationIndicator =
    React.useRef<d3.Selection<SVGTextElement, unknown, null, undefined>>();
  const mainDurationIndicator =
    React.useRef<d3.Selection<SVGTextElement, unknown, null, undefined>>();
  const mainDateAxis = React.useRef<d3.Axis<any>>();
  const mainMonthAxis = React.useRef<d3.Axis<any>>();
  const miniDateAxis = React.useRef<d3.Axis<any>>();
  const miniMonthAxis = React.useRef<d3.Axis<any>>();
  const dateRange = React.useRef<{ from: number | null; to: number | null }>({
    from: null,
    to: null,
  });
  const includeSensors = React.useRef<number[]>(
    map(filter(enabledSensors, { enabled: true }), (sensor) => sensor.id)
  );

  const chartRef = React.useRef<HTMLDivElement>();
  const [chartId] = React.useState(`history-swimlane-${uuidv4()}`);
  const [maxWidth, setMaxWidth] = React.useState<number>(width);
  const [maxBrushExtent, setMaxBrushExtent] = React.useState<number>(width);
  const [minimumBrushSize] = React.useState(70);
  const [sensorSorting, setSensorSorting] = useImmer<{ [key: number]: number }>(
    {}
  );
  const [moduleSensorData, setModuleSensorData] =
    React.useState<IGetModuleSensorData | null>();
  const sensorModuleHistoryProvider = useSenseModuleHistory();
  const [selectedInterval] = React.useState(availableIntervals[0]);
  const classes = componentStyles();

  const fetchData = React.useCallback(() => {
    if (dateRange.current.from) {
      return sensorModuleHistoryProvider
        .fetchModuleSensorData({
          from: moment(dateRange.current.from),
          to: moment(dateRange.current.to),
          sensors: includeSensors.current,
        })
        .then((data: IGetModuleSensorData) => {
          setModuleSensorData(data);
          return Promise.resolve();
        });
    }
    return Promise.resolve();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [includeSensors, dateRange.current.from, dateRange.current.to, dateRange.current]);

  const setBrushToDefault = React.useCallback(() => {
    const max = miniHorizontalScale.current.range()[1];
    const right = max;
    const left = max - Math.round(max * 0.25);

    setTimeout(() => {
      const brushDOM = document.querySelector(".brush.x");

      d3.select(brushDOM)
        .transition()
        .call(brush.current.move as any, [left, right]);
    }, 300);
  }, []);

  const setBrushToSensorValue = React.useCallback(
    (selectedSensorValue) => {
      const posX = miniHorizontalScale.current(
        moment(selectedSensorValue.ts).toDate()
      );
      let left = posX - minimumBrushSize / 2;
      let right = posX + minimumBrushSize / 2;

      if (right > maxBrushExtent) {
        left = maxBrushExtent - minimumBrushSize;
        right = maxBrushExtent;
      } else if (left < 0) {
        left = 0;
        right = minimumBrushSize;
      }

      const brushDOM = document.querySelector(".brush.x");

      d3.select(brushDOM)
        .transition()
        .call(brush.current.move as any, [left, right]);
      d3.select(brushDOM)
        .transition()
        .call(brush.current.move as any, [left, right]);
    },
    [minimumBrushSize, brush, maxBrushExtent]
  );

  /**
   * This method makes a period selected
   */
  const makePeriodActive = React.useCallback((element) => {
    if (element) {
      const isActive = element.classed("active");

      // Disable any other element that were active
      //
      const otherElements = document.querySelectorAll("rect.active");
      each(otherElements, (otherElement) => {
        d3.select(otherElement).classed("active", false);
      });

      if (isActive) {
        element.classed("active", false);
      } else {
        element.classed("active", true);
      }
    }
  }, []);

  const selectSensorValue = React.useCallback(
    (selectedSensorValue, waitTime = 0) => {
      setBrushToSensorValue(selectedSensorValue);

      // Sometimes we need to wait for the rendering of the pagination method is done before
      // we select another rect
      //
      setTimeout(() => {
        // Select the new element
        //
        const rectDOM = window.document.querySelector(
          `.event-${selectedSensorValue.sensor_id}-${moment(
            selectedSensorValue.ts
          ).valueOf()}`
        );
        if (rectDOM) {
          makePeriodActive(
            d3.select(
              document.querySelector(
                `.event-${selectedSensorValue.sensor_id}-${moment(
                  selectedSensorValue.ts
                ).valueOf()}`
              )
            )
          );
        }
      }, waitTime);
    },
    [makePeriodActive, setBrushToSensorValue]
  );

  /**
   * Generates a single path for each item class in the mini display
   * ugly - but draws mini 2x faster than append lines or line generator
   * is there a better way to do a bunch of lines as a single path with d3
   **/
  const getPaths = React.useCallback(
    (items: IHistorySwimlaneItem[]) => {
      const paths = {};
      let d: IHistorySwimlaneItem | null = null;
      const offset = 0.5 * miniVerticalScale.current(1) + 0.5;
      const result = [];

      const startDate = new Date(dateRange.current.from);
      const endDate = new Date(dateRange.current.to);

      for (let i = 0; i < items.length; i++) {
        d = items[i];
        if (!paths[d.className]) {
          paths[d.className] = "";
        }

        if (!isNull(d.value) && d.value !== "I" && d.value !== "0") {
          let start = new Date(d.start);
          let end = new Date(d.end);

          if (moment(start).isBefore(startDate)) {
            start = startDate;
          }

          if (moment(end).isAfter(endDate)) {
            end = endDate;
          }

          if (moment(start).isBefore(end)) {
            paths[d.className] += [
              "M",
              miniHorizontalScale.current(start),
              miniVerticalScale.current(d.lane) + offset,
              "H",
              miniHorizontalScale.current(end),
            ].join(" ");
          }
        }
      }

      for (const className in paths) {
        result.push({
          className: className,
          path: paths[className],
        });
      }
      return result;
    },
    [miniVerticalScale, dateRange]
  );

  const display = React.useCallback(
    (items: IHistorySwimlaneItem[], lanes: IHistorySwimlaneLane[]) => {
      // Set start/end indicators
      //
      const startDate = moment(dateRange.current.from);
      const endDate = moment(dateRange.current.to);
      let periods = undefined;
      const now = new Date();

      const extent = (brush.current.extent() as any)();
      const minExtent = extent[1][0];
      const maxExtent = extent[1][1];

      const newStart = moment(minExtent).toDate();
      const newEnd = moment(maxExtent).toDate();

      miniStartTimeIndicator.current.text(startDate.format("DD-MM HH:mm:ss"));
      miniEndTimeIndicator.current.text(endDate.format("DD-MM HH:mm:ss"));

      mainStartTimeIndicator.current.text(
        moment(newStart).format("DD-MM HH:mm:ss")
      );

      mainEndTimeIndicator.current.text(
        moment(newEnd).format("DD-MM HH:mm:ss")
      );

      miniDurationIndicator.current.text(
        moment.duration(+endDate - +startDate).humanize()
      );

      mainDurationIndicator.current.text(
        moment.duration(+newEnd - +newStart).humanize()
      );

      const visItems = items.filter(
        (d) =>
          moment(d.realStart).isBefore(moment(newEnd)) &&
          moment(d.realEnd).isAfter(newStart)
      );

      // Snap the items to the new extent so things like labels can be properly centered in the item rect
      //
      visItems.forEach((d) => {
        d.start = Math.max( d.realStart, dateRange.current.from, minExtent );
        d.end = Math.min( d.realEnd, dateRange.current.to, maxExtent );
      });

      const chart = d3.select(`#${chartId}`);

      chart.select(".mini .brush").call(
        brush.current.extent([
          [0, 0],
          [newStart as any, lanes.length * 6 + 50],
        ])
      );

      mainHorizontalScale.current.domain([newStart, newEnd]);

      // Correct the ticks on the axis
      //
      const diff = maxExtent - minExtent;

      // Larger than 20 hours
      //
      if (diff > 72000000) {
        mainMonthAxis.current
          .ticks(d3.timeDay.every(1))
          .tickFormat(d3.timeFormat("%x"));
        mainDateAxis.current
          .ticks(d3.timeHour.every(1))
          .tickFormat(d3.timeFormat("%H"));
      }
      // Larger than 10 hours
      //
      else if (diff > 36000000) {
        mainMonthAxis.current
          .ticks(d3.timeHour.every(1))
          .tickFormat(d3.timeFormat("%H"));
        mainDateAxis.current
          .ticks(d3.timeMinute.every(30))
          .tickFormat(d3.timeFormat("%M"));
      }
      // Larger then 1 hour
      //
      else if (diff > 3600000) {
        mainMonthAxis.current
          .ticks(d3.timeMinute.every(15))
          .tickFormat(d3.timeFormat("%H:%M"));
        mainDateAxis.current
          .ticks(d3.timeMinute.every(10))
          .tickFormat(d3.timeFormat("%M"));
      }
      // Larger then 1/2 hour
      //
      else if (diff > 1800000) {
        mainMonthAxis.current
          .ticks(d3.timeMinute.every(15))
          .tickFormat(d3.timeFormat("%H:%M"));
        mainDateAxis.current
          .ticks(d3.timeMinute.every(5))
          .tickFormat(d3.timeFormat("%M"));
      }

      // Lesser than 1/2 hour
      //
      else {
        mainMonthAxis.current
          .ticks(d3.timeMinute.every(5))
          .tickFormat(d3.timeFormat("%H:%M"));
        mainDateAxis.current
          .ticks(d3.timeMinute.every(1))
          .tickFormat(d3.timeFormat("%M"));
      }

      // shift the today line
      //
      const main = chart.select(".main");
      chart
        .select(".main.todayLine")
        .attr("x1", mainHorizontalScale.current(now) + 0.5)
        .attr("x2", mainHorizontalScale.current(now) + 0.5);

      // update the axis
      //
      main.select(".main.axis.date").call(mainDateAxis.current);
      main.select(".main.axis.month").call(mainMonthAxis.current);

      // udate the item rects
      //
      const itemRects = chart.select(".item-rects");

      periods = itemRects
        .selectAll(".period")
        .data(visItems, (d: any) => d.id)

        .attr("transform", (d) => {
          const start = mainHorizontalScale.current(new Date(d.start));
          const yPos =
            mainVerticalScale.current(d.lane) +
            0.1 * mainVerticalScale.current(1);
          return `translate(${start},${yPos})`;
        });

      itemRects
        .selectAll(".period rect")
        .attr(
          "width",
          (d: IHistorySwimlaneItem) =>
            mainHorizontalScale.current(new Date(d.end)) -
            mainHorizontalScale.current(new Date(d.start))
        );

      itemRects
        .selectAll(".period .itemLabel")
        .attr("x", (d: IHistorySwimlaneItem) => {
          const rectWidth =
            mainHorizontalScale.current(new Date(Math.min( d.end, maxExtent ))) -
            mainHorizontalScale.current(new Date(Math.max( d.start, minExtent )));
          return rectWidth / 2;
        });

      itemRects
        .selectAll(".period image")
        .attr("x", (d: IHistorySwimlaneItem) => {
          const start = mainHorizontalScale.current(new Date(d.start));
          const end = mainHorizontalScale.current(new Date(d.end));
          return (end - start) / 2 - 6;
        });

      const period = periods
        .enter()
        .append("g")
        .attr("class", "period")
        .attr("transform", (d: IHistorySwimlaneItem) => {
          const start = mainHorizontalScale.current(new Date(d.start));
          const yPos =
            mainVerticalScale.current(d.lane) +
            0.1 * mainVerticalScale.current(1);
          return `translate(${start},${yPos})`;
        });

      // Draw the rectangle representing the period
      //
      period
        .append("rect")
        .attr("x", 0)
        .attr("y", 0)
        .data(visItems)
        .style("fill", (d: IHistorySwimlaneItem) => d.color)
        .attr("width", (d) => {
          return (
            mainHorizontalScale.current(new Date(d.end)) -
            mainHorizontalScale.current(new Date(d.start))
          );
        })
        .attr("height", () => {
          return 0.8 * mainVerticalScale.current(1);
        })
        .attr("class", (d: IHistorySwimlaneItem, index: number) => {
          let hiddenClass = "";
          let numberClass = "";
          let isEmptyClass = "";
          let isOddOrEvent = " odd";
          const width =
            mainHorizontalScale.current(new Date(d.end)) -
            mainHorizontalScale.current(new Date(d.start));

          // Check for odds or even
          //
          if (index % 2 === 0) {
            isOddOrEvent = " even";
          }

          if (isNumber(d.value)) {
            numberClass = " number";
          }

          if (isNull(d.value) || d.value === "I" || d.value === "0") {
            isEmptyClass = " is-empty";
          }

          if (width < 3) {
            hiddenClass = " hidden";
          }

          return `mainItem ${d.className} event-${d.sensor}-${moment(
            d.start
          ).valueOf()} value-${
            d.value
          }${hiddenClass}${numberClass}${isEmptyClass}${isOddOrEvent}`;
        })
        .attr("data-value", (d: IHistorySwimlaneItem) => {
          return d.value;
        })
        .on("click", function () {
          makePeriodActive(d3.select(this));
        });

      // Draw the label on top of the rectangle
      //
      period
        .append("text")
        .text((d: IHistorySwimlaneItem) => {
          let value = d.value;

          switch (value) {
            case "D":
              value = "↓";
              break;
            case "U":
              value = "↑";
              break;
            case "I":
            case "O":
            case "C":
            case "X":
              value = "";
              break;
          }

          if ( [ 50, 104, 504, 524 ].includes( d.sensor )) {
            switch ( value ) {
              case "1":
                  value = "✔";
                  break;
              case "0":
                  value = "✕";
                  break;
            }
          } else if ( [ 103, 105, 550, 210, 212 ].includes( d.sensor )) {
            switch ( value ) {
              case "1":
                value = "✕";
                break;
              case "0":
                value = "✔";
                break;
            }
          }


          return value;
        })
        .attr("x", (d: IHistorySwimlaneItem) => {
          const rectWidth =
            mainHorizontalScale.current(new Date(Math.min( d.end, maxExtent ))) -
            mainHorizontalScale.current(new Date(Math.max( d.start, minExtent )));
          return rectWidth / 2;
        })
        .attr("y", 13)
        .attr("text-anchor", "middle")
        .attr("class", "itemLabel");

      period
        .filter((d: IHistorySwimlaneItem) => {
          return includes(["O", "C", "X"], d.value);
        })
        .append("image")
        .attr("href", (d: IHistorySwimlaneItem) => {
          let source = "/assets/images/ic_elavator_close_24px.png";

          switch (d.value) {
            case "O":
              source = "/assets/images/ic_elavator_open_24px.png";
              break;
            case "X":
              source = "/assets/images/ic_lock_closed.svg";
              break;
          }

          return source;
        })
        .attr("x", (d: IHistorySwimlaneItem) => {
          const start = mainHorizontalScale.current(new Date(d.start));
          const end = mainHorizontalScale.current(new Date(d.end));
          return (end - start) / 2 - 6;
        })
        .attr("y", 3)
        .attr("width", 12)
        .attr("height", 12);

      periods.exit().remove();
    },
    [chartId, dateRange, makePeriodActive]
  );

  /**
   * Call to draw the graph elements
   */
  const drawChart = React.useCallback(() => {
    if (chartRef.current && moduleSensorData) {
      const lanes: IHistorySwimlaneLane[] = [];
      const items: IHistorySwimlaneItem[] = [];

      const startDate = +moment(dateRange.current.from).toDate();
      const endDate = +moment(dateRange.current.to).toDate();

      let laneCounter = 0;
      let itemCounter = 0;

      // Build the movement chart points
      //
      each(moduleSensorData, (dataSet) => {
        const sensorId = parseInt(get(dataSet, "sensor.id"), 10);

        // this.$log.log( '[HISTORY] Pushing lane', sensorId );

        // Create labels
        //
        lanes.push({
          id: laneCounter,
          label: translateSensorId(sensorId),
          sensor: sensorId,
        });

        // Reverse the rows to work from front to back
        //
        each(dataSet?.rows?.reverse(), (row) => {
          const startTime = moment(row.ts).toDate();
          let endTime = row.next_ts ? +moment(row.next_ts).toDate() : endDate;

          if (endTime > Date.now()) {
            endTime = +Date.now();
          }

          let actualValue = row.val;

          // The sensor values of 501 and 514 and 515 are zero based. But we need to show it one based
          //
          if (includes([501, 514, 515], sensorId)) {
            if (!isNaN(parseInt(actualValue, 10))) {
              actualValue = parseInt(actualValue, 10) + 1;
            } else if (!actualValue) {
              actualValue = null;
            }
          }

          items.push({
            id: itemCounter,
            lane: sensorSorting[sensorId],
            start: Math.max( +startTime, startDate ),
            realStart: +startTime,
            end: Math.min( +endTime, endDate),
            realEnd: +endTime,
            value: actualValue,
            color: sensorValueColorCode(actualValue, sensorId),
            className: "past",
            sensor: sensorId,
            desc: "-",
            next: row.next_ts
              ? {
                  ts: moment(row.next_ts).toDate(),
                  val: row.next_val,
                  sensor_id: sensorId,
                }
              : null,
            prev: row.prev_ts
              ? {
                  ts: moment(row.prev_ts).toDate(),
                  val: row.prev_val,
                  sensor_id: sensorId,
                }
              : null,
          });

          itemCounter++;
        });

        laneCounter++;
      });

      const margin = {
        top: 45,
        right: 24,
        bottom: 20,
        left: 200,
      };

      const width = maxWidth - margin.left - margin.right;
      const height = 600 - margin.top - margin.bottom;
      const miniHeight = lanes.length * 6 + 50;
      const mainHeight = height - miniHeight - 160;
      const mainRowHeight = mainHeight / lanes.length;
      const now = new Date();

      const minMaxExtent = d3.extent(lanes, (d) => d.id);

      // Set the max brush extent
      //
      setMaxBrushExtent(width);

      // The scales
      //
      mainHorizontalScale.current = d3
        .scaleTime()
        .domain([dateRange.current.from, dateRange.current.to])
        .range([0, width])
        .nice();

      miniHorizontalScale.current = d3
        .scaleTime()
        .domain([dateRange.current.from, dateRange.current.to])
        .range([0, width])
        .nice();

      mainVerticalScale.current = d3
        .scaleLinear()
        .domain([minMaxExtent[0], minMaxExtent[1] + 1])
        .range([0, mainHeight])
        .nice();

      miniVerticalScale.current = d3
        .scaleLinear()
        .domain([minMaxExtent[0], minMaxExtent[1] + 1])
        .range([0, miniHeight])
        .nice();

      // UI elements
      //
      const container = chartRef.current.querySelector(
        `#${chartId}`
      ) as HTMLDivElement;

      // Remove any previous rendered DOM element
      //
      container.innerHTML = "";
      const timeDisplays = window.document.querySelectorAll(
        `.${styles.miniTimeDisplay}`
      );
      each(timeDisplays, (timeDisplay) =>
        timeDisplay.parentNode.removeChild(timeDisplay)
      );

      const chart = d3
        .select(container)
        .append("svg:svg")
        .attr("width", width + margin.right + margin.left)
        .attr("height", height + margin.top + margin.bottom)
        .attr("version", "1.1")
        .attr("xmlns", "http://www.w3.org/2000/svg")
        .attr(
          "viewBox",
          `0 0 ${width + margin.right + margin.left} ${
            height + margin.top + margin.bottom
          }`
        )
        .attr("class", styles.chart);

      chart
        .append("defs")
        .append("clipPath")
        .attr("id", "clip")
        .append("rect")
        .attr("width", width)
        .attr("height", mainHeight);

      const mini = chart
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
        .attr("width", width)
        .attr("height", miniHeight)
        .attr("class", "mini")
        .on("mouseenter", () => {
          miniTimeIndicator.current
            .attr("y1", 0)
            .attr("y2", miniHeight)
            .attr("x1", 0)
            .attr("x2", 0);

          miniTimeIndicator.current
            .transition()
            .duration(500)
            .style("opacity", 1);

          miniTimeDisplay.current
            .transition()
            .duration(500)
            .style("opacity", 1);
        })
        .on("mousemove", function () {
          const coordinates = d3.mouse(this);
          let left = coordinates[0];
          const point = miniHorizontalScale.current.invert(coordinates[0]);
          const dateTime = moment(point.getTime());
          const offset = container.offsetTop + 15;

          // Adjust the position a little to support the drag-and-drop functionality
          //
          left += 3;

          if (left < 0) {
            left = 0;
          }

          // Move line
          //
          miniTimeIndicator.current.attr("x1", left);
          miniTimeIndicator.current.attr("x2", left);

          // Move time display
          //
          miniTimeDisplay.current
            .text(dateTime.format("DD MMMM YYYY [tijd:] HH:mm:ss"))
            .style("top", offset + "px")
            .style("left", coordinates[0] + 70 + "px");
        })
        .on("mouseleave", () => {
          miniTimeIndicator.current
            .transition()
            .style("opacity", 1e-6)
            .duration(500);

          miniTimeDisplay.current
            .transition()
            .duration(500)
            .style("opacity", 1e-6);
        });

      miniStartTimeIndicator.current = mini
        .append("text")
        .attr("class", "time-indicator")
        .attr("y", -35)
        .style("text-ancho", "start")
        .text("-");

      mini
        .append("line")
        .attr("y1", -25)
        .attr("y2", 0)
        .attr("x1", 0.5)
        .attr("x2", 0.5)
        .attr("class", styles.timeIndicatorLine);

      miniEndTimeIndicator.current = mini
        .append("text")
        .attr("class", "time-indicator")
        .attr("y", -35)
        .attr("x", width)
        .style("text-anchor", "end")
        .text("-");

      mini
        .append("line")
        .attr("y1", -25)
        .attr("y2", 0)
        .attr("x1", width + 0.5)
        .attr("x2", width + 0.5)
        .attr("class", styles.timeIndicatorLine);

      miniDurationIndicator.current = mini
        .append("text")
        .attr("class", "time-indicator")
        .attr("y", -35)
        .attr("x", width / 2)
        .style("text-anchor", "middle")
        .text("-");

      // Append a big white block at the back, because else mouseover doesn't work
      //
      mini
        .append("rect")
        .attr("width", width)
        .attr("height", miniHeight)
        .attr("fill", "white")
        .attr("class", "background");

      const main = chart
        .append("g")
        .attr(
          "transform",
          "translate(" +
            margin.left +
            "," +
            (miniHeight + margin.top + 80) +
            ")"
        )
        .attr("width", width)
        .attr("height", mainHeight)
        .attr("class", "main")
        .on("mouseenter", () => {
          mainTimeIndicator.current
            .attr("y1", 0)
            .attr("y2", mainHeight)
            .attr("x1", 0)
            .attr("x2", 0);

          mainTimeIndicator.current
            .transition()
            .duration(500)
            .style("opacity", 1);

          mainTimeDisplay.current
            .transition()
            .duration(500)
            .style("opacity", 1);

          rowHighlight.current.transition().duration(500).style("opacity", 1);
        })
        .on("mousemove", function () {
          const coordinates = d3.mouse(this);
          let left = coordinates[0];
          const point = mainHorizontalScale.current.invert(coordinates[0]);
          const dateTime = moment(point.getTime());
          const offset = container.offsetTop + miniHeight + margin.top + 45;

          // Hight light the row
          //
          const posY =
            Math.floor(coordinates[1] / mainRowHeight) * mainRowHeight;
          rowHighlight.current.attr("y", posY);

          // Adjust the position a little to support the drag-and-drop functionality
          //
          left -= 5;

          if (left < 0) {
            left = 0;
          }

          // Move line
          //
          mainTimeIndicator.current.attr("x1", left);
          mainTimeIndicator.current.attr("x2", left);

          // Move time display
          //
          mainTimeDisplay.current
            .text(dateTime.format("DD MMMM YYYY [tijd:] HH:mm:ss"))
            .style("top", offset + "px")
            .style("left", coordinates[0] + 70 + "px");
        })
        .on("mouseleave", () => {
          mainTimeIndicator.current
            .transition()
            .style("opacity", 1e-6)
            .duration(500);

          mainTimeDisplay.current
            .transition()
            .duration(500)
            .style("opacity", 1e-6);

          rowHighlight.current
            .transition()
            .duration(500)
            .style("opacity", 1e-6);
        });

      const mainBackgroundUI = main
        .append("g")
        .attr("transform", "translate(0,0)")
        .attr("width", width)
        .attr("height", mainHeight)
        .attr("class", "main-background-ui");

      // Append a big white block at the back, because else mouseover doesn't work
      //
      mainBackgroundUI
        .append("rect")
        .attr("width", width)
        .attr("height", mainHeight)
        .attr("fill", "white")
        .attr("class", "background");

      // Rows for highlighting
      //
      rowHighlight.current = mainBackgroundUI
        .append("rect")
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", width)
        .attr("height", mainHeight / lanes.length)
        .attr("fill", "#F3C46D")
        .style("opacity", 1e-6);

      mini
        .append("image")
        .attr("href", "/assets/images/arrow.svg")
        .attr("x", -105)
        .attr("y", 50)
        .attr("width", 131)
        .attr("height", 135);

      mainStartTimeIndicator.current = main
        .append("text")
        .attr("class", "time-indicator")
        .attr("y", mainHeight + margin.bottom + 10)
        .attr("x", 0)
        .style("text-ancho", "start")
        .text("-");

      main
        .append("line")
        .attr("y1", mainHeight)
        .attr("y2", mainHeight + 20)
        .attr("x1", 0.5)
        .attr("x2", 0.5)
        .attr("class", styles.timeIndicatorLine);

      mainEndTimeIndicator.current = main
        .append("text")
        .attr("class", "time-indicator")
        .attr("y", mainHeight + margin.bottom + 10)
        .attr("x", width)
        .style("text-anchor", "end")
        .text("-");

      main
        .append("line")
        .attr("y1", mainHeight)
        .attr("y2", mainHeight + 20)
        .attr("x1", width + 0.5)
        .attr("x2", width + 0.5)
        .attr("class", styles.timeIndicatorLine);

      mainDurationIndicator.current = main
        .append("text")
        .attr("class", "time-indicator")
        .attr("y", mainHeight + margin.bottom + 10)
        .attr("x", width / 2)
        .style("text-anchor", "middle")
        .text("-");

      main
        .append("g")
        .selectAll(".laneLines")
        .data(lanes)
        .enter()
        .append("line")
        .attr("x1", -200)
        .attr(
          "y1",
          (d) =>
            Math.round(mainVerticalScale.current(sensorSorting[d.sensor])) + 0.5
        )
        .attr("x2", width)
        .attr(
          "y2",
          (d) =>
            Math.round(mainVerticalScale.current(sensorSorting[d.sensor])) + 0.5
        )
        .attr("stroke", (d) => (d.label === "" ? "white" : "lightgray"));

      main
        .append("g")
        .selectAll(".laneText")
        .data(lanes)
        .enter()
        .append("text")
        .text((d) => d.label)
        .attr("x", -200)
        .attr("y", (d) =>
          mainVerticalScale.current(sensorSorting[d.sensor] + 0.5)
        )
        .attr("dy", "0.5ex")
        .attr("text-anchor", "start")
        .attr("class", "laneText");

      // draw the this.lanes for the mini chart
      //
      mini
        .append("g")
        .selectAll(".laneLines")
        .data(lanes)
        .enter()
        .append("line")
        .attr("x1", 0)
        .attr(
          "y1",
          (d) =>
            Math.round(miniVerticalScale.current(sensorSorting[d.sensor])) + 0.5
        )
        .attr("x2", width)
        .attr(
          "y2",
          (d) =>
            Math.round(miniVerticalScale.current(sensorSorting[d.sensor])) + 0.5
        )
        .attr("stroke", (d) => (d.label === "" ? "white" : "lightgray"));

      // draw the x axis
      //
      mainDateAxis.current = d3
        .axisBottom(mainHorizontalScale.current)
        .ticks(d3.timeHour.every(1))
        // .tickFormat(d3.timeFormat('%M'))
        .tickSize(5);

      mainMonthAxis.current = d3
        .axisTop(mainHorizontalScale.current)
        .ticks(d3.timeHour.every(1))
        // .tickFormat(d3.timeFormat('%b %Y'))
        .tickSize(5);

      miniDateAxis.current = d3
        .axisBottom(miniHorizontalScale.current)
        .ticks(d3.timeMinute.every(10))
        .tickFormat(d3.timeFormat("%H:%M"))
        .tickSize(7);

      miniMonthAxis.current = d3
        .axisTop(miniHorizontalScale.current)
        .ticks(d3.timeMinute.every(30))
        .tickFormat(d3.timeFormat("%H:%M"))
        .tickSize(7);

      main
        .append("g")
        .attr("transform", "translate(0,0)")
        .attr("class", "main axis month")
        .call(mainMonthAxis.current);

      main
        .append("g")
        .attr("transform", "translate(0," + mainHeight + ")")
        .attr("class", "main axis date")
        .call(mainDateAxis.current);

      mini
        .append("g")
        .attr("transform", "translate(0,0)")
        .attr("class", "axis month")
        .call(miniMonthAxis.current);

      mini
        .append("g")
        .attr("transform", "translate(0," + miniHeight + ")")
        .attr("class", "axis date")
        .call(miniDateAxis.current);

      // draw a line representing today's date
      //
      main
        .append("line")
        .attr("y1", 0)
        .attr("y2", mainHeight)
        .attr("class", "main todayLine")
        .attr("clip-path", "url(#clip)");

      mini
        .append("line")
        .attr("x1", () => mainHorizontalScale.current(now) + 0.5)
        .attr("y1", 0)
        .attr("x2", () => mainHorizontalScale.current(now) + 0.5)
        .attr("y2", miniHeight)
        .attr("class", "todayLine");

      // draw the items
      //
      main
        .append("g")
        .attr("class", "item-rects")
        .attr("clip-path", "url(#clip)");

      mini
        .append("g")
        .selectAll("miniItems")
        .data(getPaths(items))
        .enter()
        .append("path")
        .attr("class", (d: IHistorySwimlaneItem) => d.className)
        .attr("d", (d: any) => d.path);

      // invisible hit area to move around the selection window
      //
      brush.current = d3
        .brushX()
        .extent([
          [0, 0],
          [dateRange.current.from, dateRange.current.to],
        ])
        .on("end", function (_a, _index, nodes) {
          const selection = d3.brushSelection(first(nodes));
          // There was a manual selection
          //
          if (selection && size(selection) > 1) {
            // let minimumWidth =  10;
            const left = selection[0] as number;
            const right = selection[1] as number;

            const startNew = miniHorizontalScale.current.invert(left);
            const endNew = miniHorizontalScale.current.invert(right);
            brush.current.extent([
              [0, 0],
              [startNew as any, endNew as any],
            ]);
            display(items, lanes);
          }
          // No selection so there must be a click event
          //
          else {
            const posX = first(d3.mouse(this));
            let left = posX - minimumBrushSize / 2;
            let right = posX + minimumBrushSize / 2;
            const brushDOM = document.querySelector(".brush.x");

            if (left < 0) {
              left = 0;
              right = minimumBrushSize;
            }

            if (right > maxBrushExtent) {
              right = maxBrushExtent;
              left = maxBrushExtent - minimumBrushSize;
            }

            d3.select(brushDOM)
              .transition()
              .call(brush.current.move as any, [left, right]);
          }
        });

      // Draw the date indicators
      //
      miniTimeIndicator.current = mini
        .append("line")
        .attr("class", "mini-time-indicator")
        .style("opacity", 1e-6);

      mainTimeIndicator.current = main
        .append("line")
        .attr("class", "main-time-indicator")
        .style("opacity", 1e-6);

      miniTimeDisplay.current = d3
        .select(container)
        .append("div")
        .attr("class", styles.miniTimeDisplay)
        .text("")
        .style("opacity", 1e-6);

      mainTimeDisplay.current = d3
        .select(container)
        .append("div")
        .attr("class", styles.miniTimeDisplay)
        .text("")
        .style("opacity", 1e-6);

      mini.selectAll("rect.background").remove();

      display(items, lanes);

      // draw the selection area
      // & set initial selection
      //
      const brushSelection = mini
        .append("g")
        .attr("class", "x brush")
        .call(brush.current);

      // set the brush selection and handle logic to listen to the current dates
      //
      const nowDate = Date.now();
      const startXScale = eventStartDate
        ? +moment(eventStartDate) - 120000
        : dateRange?.current?.from;
      const posX = miniHorizontalScale.current(
        startXScale > nowDate ? nowDate : startXScale
      );

      let left = posX;
      let right = posX + minimumBrushSize;

      if (left < 0) {
        left = 0;
        right = minimumBrushSize;
      }

      brush.current.move(brushSelection, [left, right]);
    }
  }, [
    moduleSensorData,
    maxWidth,
    dateRange,
    chartId,
    getPaths,
    display,
    minimumBrushSize,
    sensorSorting,
    maxBrushExtent,
    eventStartDate,
  ]);

  const updateChart = React.useCallback(() => {
    const historyCanvas = chartRef.current.querySelector(`#${chartId}`);
    while (historyCanvas.hasChildNodes()) {
      historyCanvas.removeChild(historyCanvas.firstChild);
    }
    // Draw the graph again
    //
    return drawChart();
  }, [chartId, chartRef, drawChart]);

  const paginate = React.useCallback(
    async (direction) => {
      if (isObject(selectedInterval)) {
        const unit = selectedInterval.unit;
        const addition = direction * selectedInterval.amount;
        const newDate = moment(dateRange.current.from).add(
          addition as any,
          unit
        );

        const newDateRange = {
          from: newDate.startOf(unit as any).valueOf(),
          to: newDate.endOf(unit as any).valueOf(),
        };

        if (!isEqual(newDateRange, dateRange.current)) {
          dateRange.current = newDateRange;
        }

        // Set interval
        //
        if (addition < 0) {
          setBrushToDefault();
        }

        // Fetch the data
        //
        await fetchData();
        updateChart();
      }
    },
    [fetchData, selectedInterval, setBrushToDefault, updateChart]
  );

  /**
   * Scrub to the left
   */
  useHotkeys("left", () => {
    scrubBackwards();
  });

  /**
   * Scrub to the right
   */
  useHotkeys("right", () => {
    scrubForward();
  });

  const scrubForward = React.useCallback(async () => {
    // First check if we need to jump accross events or
    // that we need to move the brush
    //
    const selectedPeriod = chartRef.current.querySelector("rect.active");
    if (selectedPeriod) {
      const period = d3.select(selectedPeriod);
      const data = first(period.data());

      if (data.next) {
        const targetValue = data.next;
        const differenceInMilliseconds = moment(targetValue.ts).diff(
          dateRange.current.from
        );
        const domominator =
          selectedInterval.unit === "hours"
            ? 3600000
            : selectedInterval.amount * 60000;
        const differenceInUnits = differenceInMilliseconds / domominator;
        let difference = 0;

        if (differenceInMilliseconds < 0 && differenceInUnits > -1) {
          difference = -1;
        } else if (differenceInUnits > 1 || differenceInUnits < -1) {
          difference =
            Math[differenceInMilliseconds < 0 ? "floor" : "ceil"](
              differenceInUnits
            );
        }

        // Check if we're already looking at that hour
        //
        if (difference !== 0) {
          await paginate(difference);
          selectSensorValue(targetValue, 500);

          // If the difference is larger then -1, notify the user about this happening
          //
          if (Math.abs(difference) > 1) {
            // TODO: Fix this
            //
            // localScope.$mdToast.show(
            //     localScope.$mdToast.simple()
            //         .textContent( `Let op! ${Math.abs(Math.round( difference * selectedInterval.amount ))} ${selectedInterval.unit} in de tijd achteruit gesprongen` )
            //         .position( "bottom right" )
            //         .hideDelay(30000)
            //     );
          }
        } else {
          selectSensorValue(targetValue, 50);
        }
      }
    }
    // No period selected use brush
    //
    else {
      const brushDOM = document.querySelector(".brush.x") as SVGGElement;
      if (brushDOM) {
        const selection = d3.brushSelection(brushDOM);
        if (selection) {
          const width = (selection[1] as number) - (selection[0] as number);
          const left = (selection[0] as number) + width;
          const right = (selection[1] as number) + width;

          // We don't want to see the date indicator in this instance
          //
          miniTimeDisplay.current.style("opacity", 1e-6);
          miniTimeIndicator.current.style("opacity", 1e-6);

          if (right > maxBrushExtent) {
            await paginate(1);
            // setTimeout(() => {
            //   right = width;
            //   left = 0;
            //   brushDOM = document.querySelector(".brush.x");
            //   d3.select(brushDOM).transition().call(brush.current.move, [left, right]);
            // }, 500);
          } else {
            d3.select(brushDOM)
              .transition()
              .call(brush.current.move, [left, right]);
          }
        }
      }
    }
  }, [
    dateRange,
    selectedInterval,
    paginate,
    selectSensorValue,
    maxBrushExtent,
  ]);

  const scrubBackwards = React.useCallback(async () => {
    // First check if we need to jump accross events or
    // that we need to move the brush
    //
    const selectedPeriod = chartRef.current.querySelector("rect.active");

    if (selectedPeriod) {
      const period = d3.select(selectedPeriod);
      const data = first(period.data());

      if (data.prev) {
        const targetValue = data.prev;
        const differenceInMilliseconds = moment(targetValue.ts).diff(
          dateRange.current.from
        );
        const domominator =
          selectedInterval.unit === "hours"
            ? 3600000
            : selectedInterval.amount * 60000;
        const differenceInUnits = differenceInMilliseconds / domominator;
        let difference = 0;

        if (differenceInMilliseconds < 0 && differenceInUnits > -1) {
          difference = -1;
        } else if (differenceInUnits > 1 || differenceInUnits < -1) {
          difference =
            Math[differenceInMilliseconds < 0 ? "floor" : "ceil"](
              differenceInUnits
            );
        }

        // Check if we're already looking at that hour
        //
        if (difference !== 0) {
          await paginate(difference);
          selectSensorValue(targetValue, 500);

          // If the difference is larger then -1, notify the user about this happening
          //
          // TODO: Fix tis
          // if (Math.abs(difference) > 1) {
          //   localScope.$mdToast.show(
          //     localScope.$mdToast.simple()
          //     .textContent(`Let op! ${Math.abs(Math.round( difference * selectedInterval.amount ))} ${selectedInterval.unit} in de tijd achteruit gesprongen`)
          //     .position("bottom right")
          //     .hideDelay(30000)
          //   );
          // }
        } else {
          selectSensorValue(targetValue, 50);
        }
      }
    }
    // No period selected use brush
    //
    else {
      const brushDOM = chartRef.current.querySelector(
        ".brush.x"
      ) as SVGGElement;

      if (brushDOM) {
        const selection = d3.brushSelection(brushDOM);

        if (selection) {
          const width = (selection[1] as number) - (selection[0] as number);
          const left = (selection[0] as number) - width;
          const right = (selection[1] as number) - width;

          // We don't want to see the date indicator in this instance
          //
          miniTimeDisplay.current.style("opacity", 1e-6);
          miniTimeIndicator.current.style("opacity", 1e-6);

          if (left < 0) {
            await paginate(-1);
            // setTimeout(() => {
            // right = maxBrushExtent;
            // left = maxBrushExtent - width;
            // brushDOM = document.querySelector(".brush.x");
            // d3.select(brushDOM).transition().call(brush.current.move, [left, right]);
            // }, 500);
          } else {
            console.log("max # right");
            d3.select(brushDOM)
              .transition()
              .call(brush.current.move, [left, right]);
          }
        }
      }
    }
  }, [dateRange, selectedInterval, paginate, selectSensorValue]);

  /**
   * Effect that will draw the graph and sets everything up
   */
  React.useEffect(() => {
    if (maxWidth) {
      const now = moment();
      const periodEndMoment = moment(periodEndDate);

      // Initial date range. We assume most people will look at the most current data
      //
      const newDateRange = {
        from: now
          .clone()
          .startOf(selectedInterval.unit as any)
          .valueOf(),
        to: now.endOf(selectedInterval.unit as any).valueOf(),
      };

      // If the period is in the past we adjust the initial date range
      // to the end of the period.
      //
      if (periodEndMoment.isSameOrBefore(now)) {
        newDateRange.from = periodEndMoment
          .startOf(selectedInterval.unit as any)
          .valueOf();
        newDateRange.to = periodEndMoment
          .endOf(selectedInterval.unit as any)
          .valueOf();
      }

      if (!isEqual(dateRange.current, newDateRange)) {
        dateRange.current = newDateRange;
      }

      // TODO: As of this writing we don't have a solid way to determine backdoor functionality
      //
      //find( this.sensors, { id: 503 } ).enabled = this.$ednlEventServices.currentModule.capabilities.hasBackDoor ? true : false;

      // Set sorting logic
      //
      const sortingLogic = {};
      each(includeSensors.current, (sensor, idx) => {
        sortingLogic[sensor] = idx;
      });

      setSensorSorting((sorting) => {
        if (!isEqual(sorting, sortingLogic)) {
          return sortingLogic;
        }
      });

      // Initial fetch data when sensors are known
      //
       //fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodEndDate]);

  React.useEffect(() => {
    if (moduleSensorData) {
      drawChart();
    }
  }, [moduleSensorData, drawChart]);

  const [windowSize, setWindowSize] = React.useState(0);

  React.useEffect(() => {
    function updateSize() {
      setWindowSize(window.innerWidth <= 0 ? 1 : window.innerWidth);
    }

    window.addEventListener("resize", updateSize);

    updateSize();

    return () => window.removeEventListener("resize", updateSize);
  }, []);

  React.useEffect(() => {
    if (chartRef.current) {
      setMaxWidth(width);
    }
  }, [windowSize, width]);

  React.useEffect(() => {
    if(dateRangeProps) {
      dateRange.current = dateRangeProps;
      fetchData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRangeProps]);

  return (
    <>
      {!moduleSensorData && (
        <div className={classes.loading}>
          <CircularProgress color="secondary" />
        </div>
      )}
      {/* Hide the main UI when there is no data to work with */}
      {moduleSensorData && (
        <>
          <div ref={chartRef}>
            <div id={chartId} className={classes.chart}></div>
          </div>
        </>
      )}
    </>
  );
};

export default ElevatorHistorySwimlanes;
